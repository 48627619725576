/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InventoryIcon from "@mui/icons-material/Inventory";
import LineChart from "../../components/LineChart";
import { useNavigate } from "react-router-dom";
import { useDashboardMutation } from "../../services/notification";
import { Dashboard } from "../../types/General";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const navigate = useNavigate();
  const [dashboardMutation] = useDashboardMutation();

  const [dashboard, setDashboard] = useState<Dashboard | null>(null);
  const [orderFilter, setOrderFilter] = useState<string>("monthly");
  const [orderNames, setOrderNames] = useState<string[]>([]);
  const [orderValues, setOrderValues] = useState<number[]>([]);
  const [revenueFilter, setRevenueFilter] = useState<string>("monthly");
  const [revenueNames, setRevenueNames] = useState<string[]>([]);
  const [revenueValues, setRevenueValues] = useState<number[]>([]);

  const handleOrderChange = (event: SelectChangeEvent) => {
    setOrderFilter(event.target.value as string);
  };

  const handleRevenueChange = (event: SelectChangeEvent) => {
    setRevenueFilter(event.target.value as string);
  };

  const graphOrderData = {
    labels: orderNames,
    datasets: [
      {
        label: "Orders",
        data: orderValues,
        borderColor: "#204e33",
        backgroundColor: "#204e33",
      },
    ],
  };

  const graphRevenueData = {
    labels: revenueNames,
    datasets: [
      {
        label: "Revenue",
        data: revenueValues,
        borderColor: "#204e33",
        backgroundColor: "#204e33",
      },
    ],
  };

  const getDashboard = async () => {
    try {
      const response = await dashboardMutation({
        postType: "",
        totalOrders: orderFilter,
        totalUsers: "",
        auctionType: "",
        totalRevenue: revenueFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setDashboard(response?.data);
        setOrderNames(Object.keys(response?.data?.totalOrders));
        setOrderValues(Object.values(response?.data?.totalOrders));
        setRevenueNames(Object.keys(response?.data?.totalRevenue));
        setRevenueValues(Object.values(response?.data?.totalRevenue));
      }
    } catch (error: any) {
      // showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getDashboard();
  }, [orderFilter, revenueFilter]);

  return (
    <div className="main_loyout">
      {/* <Loader isLoad={isLoading} /> */}
      <div className="dashboard">
        <h1>Dashboard</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Users</h3>
              <h4>{dashboard?.userCount || 0}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-posts")}
          >
            <WysiwygIcon className="svg_icn" />
            <div>
              <h3>Total Post for Sale</h3>
              <h4>{dashboard?.sellPostCount || 0}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-posts", { state: "auction" })}
          >
            <WysiwygIcon className="svg_icn" />
            <div>
              <h3>Total Post for Auction</h3>
              <h4>{dashboard?.auctionPostCount || 0}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-revenue")}
          >
            <AttachMoneyIcon className="svg_icn" />
            <div>
              <h3>Revenue analytics</h3>
              <h4>{dashboard?.revenueCount || 0} SAR</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageservices")}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>Service Management</h3>
              <h4>{dashboard?.orderCount || 0}</h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item xs={6}>
          <Item className="cards dashGraph_item">
            <h2>
              Total Orders
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={orderFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleOrderChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphOrderData} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item className="cards dashGraph_item">
            <h2>
              Total Revenue
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={revenueFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleRevenueChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphRevenueData} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoard;
