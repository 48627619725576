import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Auction, Category } from "../../types/General";
import {
  useLazyGetAllCateQuery,
  useLazyGetAuctionByIdQuery,
  useUpdateAuctionByIdMutation,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNumber, isString } from "../../utils/validations";
import moment from "moment";

const AddAuctionForSell = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [AuctionById, { isLoading }] = useLazyGetAuctionByIdQuery();
  const [editAuction, editAuctionData] = useUpdateAuctionByIdMutation();
  const [allCategory] = useLazyGetAllCateQuery();

  const [category, setCategory] = useState("");
  const [error, setError] = useState(false);
  const [auctionData, setAuctionData] = useState<Auction>();
  const [category1, setCategory1] = useState<Category[]>([]);

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const getAuctionById = async (id: string) => {
    try {
      const response = await AuctionById({ auction_id: id }).unwrap();
      if (response?.statusCode === 200) {
        if (response?.data) {
          setAuctionData(response?.data?.auction[0]);
          setCategory(response?.data?.auction[0]?.categoryData?._id);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      posttitel: auctionData?.posttitle || "",
      price: auctionData?.price || "",
      category: "",
      carPlatenumber: auctionData?.carPlatenumber || "",
      startingBid: auctionData?.startingBid || "",
      endingBid: auctionData?.endingBid || "",
      reversePrice: auctionData?.reservePrice || "",
      minOffer: auctionData?.reservePrice || "",
      biddingStartTime: auctionData?.bidingStartTime
        ? moment(auctionData.bidingStartTime).format("YYYY-MM-DDTHH:mm")
        : "",
      // biddingEndTime: auctionData?.bidingEndTime
      //   ? moment(auctionData.bidingEndTime).format("YYYY-MM-DDTHH:mm")
      //   : "",
      meaningOfPlate: auctionData?.meaningOfPlate || "",
    },
    validationSchema: Yup.object({
      posttitel: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      carPlatenumber: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      price: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 digits are required"),
      startingBid: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 digits are required"),
      // endingBid: Yup.string()
      //   .required("This field is required")
      //   .min(2, "Minimum 2 digits are required"),
      reversePrice: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 digits are required"),
      minOffer: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 digits are required"),
      meaningOfPlate: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 digits are required"),
      // biddingEndTime: Yup.string().required("This field is required"),
      biddingStartTime: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        posttitel: formik.values.posttitel,
        price: formik.values.price,
        carPlatenumber: formik.values.carPlatenumber,
        category: category,
        meaningOfPlate: formik.values.meaningOfPlate,
        startingBid: formik.values.startingBid,
        // endingBid: formik.values.endingBid,
        reservePrice: formik.values.reversePrice,
        minimumOffer: formik.values.minOffer,
        bidingStartTime: moment(formik.values.biddingStartTime)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        // bidingEndTime: moment(formik.values.biddingEndTime)
        //   .utc()
        //   .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
      console.log(body, "");

      try {
        const response = await editAuction({
          body,
          auction_id: `${id}`,
        }).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message || "");
          navigate("/manage-posts", { state: "auction" });
        }
      } catch (error: any) {
        console.log(error);
      }
    },
  });
  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategory1(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getAuctionById(id);
    }
  }, []);

  useEffect(() => {
    getAllCate();
  }, []);

  return (
    <>
      <Loader isLoad={isLoading || editAuctionData?.isLoading} />
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Edit Auction For Sale</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-posts", { state: "auction" });
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Post Title</Typography>
                    <TextField
                      inputProps={{ maxLength: 3 }}
                      hiddenLabel
                      type={"text"}
                      name="posttitel"
                      variant="outlined"
                      fullWidth
                      placeholder="Post Title"
                      id="posttitel"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      value={formik.values.posttitel}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.posttitel && formik.errors.posttitel
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Car Plate Number
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="carPlatenumber"
                      variant="outlined"
                      fullWidth
                      placeholder="Car Plate Number"
                      id="carPlatenumber"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      value={formik.values.carPlatenumber}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.carPlatenumber &&
                        formik.errors.carPlatenumber
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Category</Typography>
                    <FormControl fullWidth>
                      <Select
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {category1?.length
                          ? category1?.map((item) => (
                              <MenuItem key={item?._id} value={item?._id}>
                                {item?.categoryName || ""}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {error && !category ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Price</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="price"
                      variant="outlined"
                      fullWidth
                      placeholder="Price of the car plate"
                      id="price"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      value={formik.values.price}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={formik.touched.price && formik.errors.price}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Meaning of Plate
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="meaningOfPlate"
                      variant="outlined"
                      fullWidth
                      placeholder="Meaning of Plate"
                      id="meaningOfPlate"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      value={formik.values.meaningOfPlate}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.meaningOfPlate &&
                        formik.errors.meaningOfPlate
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <Typography className="custom_label">
                      Auction Duration
                    </Typography>
                    <FormControl fullWidth>
                      <NativeSelect
                        defaultValue={0}
                        variant="outlined"
                        inputProps={{
                          name: "PriceType",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={0}>Select</option>
                        <option value={10}>1 Hour</option>
                        <option value={20}>2 Hours</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Starting Bid
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="startingBid"
                      variant="outlined"
                      fullWidth
                      placeholder="Starting Bid"
                      id="startingBid"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      value={formik.values.startingBid}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.startingBid && formik.errors.startingBid
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <Typography className="custom_label">Ending Bid</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="endingBid"
                      variant="outlined"
                      fullWidth
                      placeholder="Ending Bid"
                      id="endingBid"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      value={formik.values.endingBid}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.endingBid && formik.errors.endingBid
                      }
                    />
                  </Grid> */}
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Reserve Price
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="reversePrice"
                      variant="outlined"
                      fullWidth
                      placeholder="Reserve Price"
                      id="reversePrice"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      value={formik.values.reversePrice}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.reversePrice &&
                        formik.errors.reversePrice
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Minimum Offer
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="minOffer"
                      variant="outlined"
                      fullWidth
                      placeholder="Minimum Offer"
                      id="minOffer"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      value={formik.values.minOffer}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.minOffer && formik.errors.minOffer
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className="custom_label">
                          Bidding Start Time
                        </Typography>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type={"datetime-local"}
                          name="biddingStartTime"
                          variant="outlined"
                          fullWidth
                          id="biddingStartTime"
                          value={formik.values.biddingStartTime}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.biddingStartTime &&
                            formik.errors.biddingStartTime
                          }
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                        <Typography className="custom_label">
                          Bidding End Time
                        </Typography>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type={"datetime-local"}
                          variant="outlined"
                          fullWidth
                          name="biddingEndTime"
                          id="biddingEndTime"
                          value={formik.values.biddingEndTime}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.biddingEndTime &&
                            formik.errors.biddingEndTime
                          }
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddAuctionForSell;
