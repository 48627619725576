import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { useUserNotificationMutation } from "../../services/notification";
import { Notification } from "../../types/General";
import { showError } from "../../constants";

const RecievedNotifications = () => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [notification, setNotification] = useState<Notification[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");

  function createData(
    CustomerName: string,
    CustomerUsername: string,
    CustomerEmail: string,
    title: string,
    message: string
  ) {
    return { CustomerName, CustomerUsername, CustomerEmail, title, message };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "abdulhussian67@gmail.com",
      "What is Lorem Ipsum?",
      "Lorem Ipsum is simply dummy text of the printing "
    ),
  ];

  const [getNotif, { isLoading }] = useUserNotificationMutation();

  const getNotification = async () => {
    try {
      const response = await getNotif({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setNotification(response?.data?.user || []);
        setTotalCount(response?.data?.userCount);
      } else {
        setNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getNotification();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Received Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">{/* <SearchBar /> */}</Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {notification?.length ? (
                notification?.map((row, i) => {
                  return (
                    <TableBody key={row?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>{row?.Title || "--"}</TableCell>
                        <TableCell sx={{ width: "400px" }}>
                          {row?.Message || "--"}
                        </TableCell>
                        {/* <TableCell>
                          {moment(row?.createdAt).format("l")}
                        </TableCell> */}
                        <TableCell align="center">
                          <Box className="table_actions">
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={5}>
                    No Notification Yet.
                  </TableCell>
                </TableBody>
              )}
              {/* <TableBody>
                {rows.map((row, i) => (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row.CustomerName}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.message}</TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody> */}
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default RecievedNotifications;
