/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useDashboardMutation } from "../../services/notification";

const Analytics = () => {
  const [dashboardMutation] = useDashboardMutation();

  const [orderFilter, setOrderFilter] = useState<string>("monthly");
  const [orderNames, setOrderNames] = useState<string[]>([]);
  const [orderValues, setOrderValues] = useState<number[]>([]);

  const [userFilter, setUserFilter] = useState<string>("monthly");
  const [userNames, setUserNames] = useState<string[]>([]);
  const [userValues, setUserValues] = useState<number[]>([]);

  const [saleFilter, setSaleFilter] = useState<string>("monthly");
  const [saleNames, setSaleNames] = useState<string[]>([]);
  const [saleValues, setSaleValues] = useState<number[]>([]);

  const [auctionFilter, setAuctionFilter] = useState<string>("monthly");
  const [auctionNames, setAuctionNames] = useState<string[]>([]);
  const [auctionValues, setAuctionValues] = useState<number[]>([]);

  const handleOrderChange = (event: SelectChangeEvent) => {
    setOrderFilter(event.target.value as string);
  };

  const handleUserChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };
  const handleSaleChange = (event: SelectChangeEvent) => {
    setSaleFilter(event.target.value as string);
  };

  const handleAuctionChange = (event: SelectChangeEvent) => {
    setAuctionFilter(event.target.value as string);
  };

  const graphOrderData = {
    labels: orderNames,
    datasets: [
      {
        label: "Orders",
        data: orderValues,
        borderColor: "#204e33",
        backgroundColor: "#204e33",
      },
    ],
  };

  const graphUsersData = {
    labels: userNames,
    datasets: [
      {
        label: "Users",
        data: userValues,
        borderColor: "#204e33",
        backgroundColor: "#204e33",
      },
    ],
  };

  const graphSaleData = {
    labels: saleNames,
    datasets: [
      {
        label: "Posts",
        data: saleValues,
        borderColor: "#204e33",
        backgroundColor: "#204e33",
      },
    ],
  };

  const graphAuctionData = {
    labels: auctionNames,
    datasets: [
      {
        label: "Auction",
        data: auctionValues,
        borderColor: "#204e33",
        backgroundColor: "#204e33",
      },
    ],
  };

  const getDashboard = async () => {
    try {
      const response = await dashboardMutation({
        postType: saleFilter,
        totalOrders: orderFilter,
        totalUsers: userFilter,
        auctionType: auctionFilter,
        totalRevenue: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setOrderNames(Object.keys(response?.data?.totalOrders));
        setOrderValues(Object.values(response?.data?.totalOrders));
        setUserNames(Object.keys(response?.data?.totalUsers));
        setUserValues(Object.values(response?.data?.totalUsers));
        setSaleNames(Object.keys(response?.data?.sellPost));
        setSaleValues(Object.values(response?.data?.sellPost));
        setAuctionNames(Object.keys(response?.data?.auctionPost));
        setAuctionValues(Object.values(response?.data?.auctionPost));
      }
    } catch (error: any) {
      // showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getDashboard();
  }, [orderFilter, userFilter, saleFilter, auctionFilter]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Reports and Analytics</h1>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item xs={6}>
            <div className="cards dashGraph_item">
              <h2>
                Total Orders
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={orderFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleOrderChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphOrderData} />
            </div>
          </Grid>
          {/* <Grid item xs={6}>
            <div className="cards dashGraph_item">
              <h2>
                Total Revenue
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid> */}
          <Grid item xs={6}>
            <div className="cards dashGraph_item">
              <h2>
                Total Users
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleUserChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphUsersData} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="cards dashGraph_item">
              <h2>
                Total Sale Post
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={saleFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleSaleChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphSaleData} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="cards dashGraph_item">
              <h2>
                Total Auction Listed
                <FormControl>
                  <FormControl>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={auctionFilter}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleAuctionChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </FormControl>
              </h2>
              <LineChart data={graphAuctionData} />
            </div>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Analytics;
