import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetSellIdQuery } from "../../services/main";
import { useEffect, useState } from "react";
import { showError } from "../../constants";
import { SellById } from "../../types/General";

const ViewPostforSell = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getSellId] = useLazyGetSellIdQuery();

  const [sellData, setSellData] = useState<SellById[]>([]);

  const getSellDetailById = async (id: string) => {
    try {
      const response = await getSellId({ sell_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setSellData(response?.data || []);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getSellDetailById(id);
    }
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Post for Sale</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-posts");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            {sellData?.map((item) => {
              return (
                <Grid
                  container
                  spacing={2}
                  className="view_box"
                  key={item?._id}
                >
                  <Grid item xs={12} mb={4} className="view_box_list">
                    <Typography className="detail_title" component="h2" mb={3}>
                      Post Summary
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Box>
                          <Typography component="h5">Post Title</Typography>
                          <Typography component="p">
                            {item?.posttitle || "--"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <Typography component="h5">
                            Car Plate Number
                          </Typography>
                          <Typography component="p">
                            {item?.carPlatenumber || "--"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <Typography component="h5">Category</Typography>
                          <Typography component="p">
                            {item?.categoryData?.categoryName || "--"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <Typography component="h5">Price Type</Typography>
                          <Typography component="p">
                            {item?.typeOfPrice || "--"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <Typography component="h5">Price</Typography>
                          <Typography component="p">
                            {item?.price || "--"} SAR
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="view_box_list">
                    <Typography className="detail_title" component="h2" mb={3}>
                      Customer Details
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Box>
                          <Typography component="h5">Customer Name</Typography>
                          <Typography component="p">
                            {item?.userId?.fullName || "--"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <Typography component="h5">Customer Email</Typography>
                          <Typography component="p">
                            {item?.userId?.email || "--"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <Typography component="h5">Customer Phone</Typography>
                          <Typography component="p">
                            {item?.userId?.countryCode
                              ? "+" + item?.userId?.countryCode
                              : ""}{" "}
                            {item?.userId?.phone || "--"}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ViewPostforSell;
