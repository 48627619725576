/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Pagination, SearchBar, WarnModal } from "../../components";
import {
  useLazyDeleteBannerIdQuery,
  useLazyGetBannerQuery,
} from "../../services/service";
import { Loader, showError, showToast } from "../../constants";
import { isValidInput } from "../../utils/validations";
import { BannerResponse } from "../../types/General";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageBanner = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [getBanner, { isLoading }] = useLazyGetBannerQuery();
  const [deleteBannerById] = useLazyDeleteBannerIdQuery();

  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [bannerRespose, setBannerRespose] = useState<BannerResponse[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllBanner = async () => {
    try {
      const response = await getBanner({
        size: 10,
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setBannerRespose(response?.data?.banner || []);
        setTotalCount(response?.data?.count);
      } else {
        setBannerRespose([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteBannerById({ banner_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        getAllBanner();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Banner"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    getAllBanner();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Banner</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {hidePermission?.isAdd || userData?.role === "ADMIN" ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-banner/add")}
                >
                  Add Banner
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Banner Name</TableCell>
                  <TableCell align="center">Post Type</TableCell>
                  <TableCell align="center">Start Date</TableCell>
                  <TableCell align="center">End Date</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {bannerRespose?.length ? (
                bannerRespose?.map((row, i) => {
                  return (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell align="center">
                          {row?.title || "--"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.role
                            ? `Post for ${row?.role.toLocaleLowerCase()}`
                            : "--"}
                        </TableCell>
                        <TableCell align="center">
                          {moment(row?.startDate).format("MMM Do YY") || "--"}
                        </TableCell>
                        <TableCell align="center">
                          {moment(row?.endDate).format("MMM Do YY") || "--"}
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            {hidePermission?.isAdd ||
                            userData?.role === "ADMIN" ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/manage-banner/edit/${row?._id}`)
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={6}>
                    No Category Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="banner"
      />
      <Pagination
        setPage={setPage}
        module={bannerRespose}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </MainContainer>
  );
};

export default ManageBanner;
