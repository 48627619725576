import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD-0JM53TkxDo5WxyummSSeni5zLyed-dA",
  authDomain: "lowhate-f39f2.firebaseapp.com",
  projectId: "lowhate-f39f2",
  storageBucket: "lowhate-f39f2.appspot.com",
  messagingSenderId: "630361815067",
  appId: "1:630361815067:web:5b28d33b00a78df67a20be",
  measurementId: "G-9JDRVZPFJF",
  // apiKey: "AIzaSyD3QYR8YULiyX_UevbuAu-63oNW2qBX4Mg",
  // authDomain: "lowhate-test-27c64.firebaseapp.com",
  // projectId: "lowhate-test-27c64",
  // storageBucket: "lowhate-test-27c64.appspot.com",
  // messagingSenderId: "622587573810",
  // appId: "1:622587573810:web:462a0f2bca67b1e4b66e2b",
  // measurementId: "G-87ZHJZHTH2",
};

const app = initializeApp(firebaseConfig);
export default app;
