import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import { useEffect, useState } from "react";
import { useLazyGetRequestByIdQuery } from "../../../services/service";
import { showError } from "../../../constants";
import { ServiceRequest, ServiceStatus } from "../../../types/General";
import moment from "moment";

const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [serviceRequest] = useLazyGetRequestByIdQuery();

  const [agent, setAgent] = useState("");
  const [requestData, setRequestData] = useState<ServiceRequest>();

  const handleChange = (event: SelectChangeEvent) => {
    setAgent(event.target.value as string);
  };

  const getRequestDetailById = async (id: string) => {
    try {
      const response = await serviceRequest({ request_id: id }).unwrap();
      if (response?.statusCode === 200) {
        console.log(response, "resss");
        setRequestData(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getRequestDetailById(id);
    }
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Request Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manageservices");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} mb={4} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Service Name</Typography>
                      <Typography component="p">
                        {requestData?.serviceType || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Date & Time</Typography>
                      <Typography component="p">
                        {moment(requestData?.createdAt).format(
                          "DD-MM-YYYY / h:mma"
                        ) || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Price</Typography>
                      <Typography component="p">
                        {requestData?.serviceFees || 0} SAR
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Status</Typography>
                      <Typography component="p">
                        {requestData?.status !== undefined
                          ? ServiceStatus[requestData.status]
                          : ""}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} mb={4} className="view_box_list">
                <Typography className="detail_title" component="h2" mb={3}>
                  User Details
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">User Name</Typography>
                      <Typography component="p">
                        {requestData?.userDetails?.userName || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">User Email</Typography>
                      <Typography component="p">
                        {requestData?.userDetails?.email || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">User Phone</Typography>
                      <Typography component="p">
                        {requestData?.userDetails?.countryCode
                          ? (requestData?.userDetails?.countryCode.includes("+")
                              ? ""
                              : "+") + requestData?.userDetails?.countryCode
                          : null}{" "}
                        {requestData?.userDetails?.phone || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="view_box_list">
                <Typography className="detail_title" component="h2" mb={3}>
                  Agent Details
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Agent Name</Typography>
                      <Typography component="p">
                        {requestData?.agentDetails?.firstName || "--"}{" "}
                        {requestData?.agentDetails?.lastName || ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Agent Email</Typography>
                      <Typography component="p">
                        {requestData?.agentDetails?.email || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Agent Phone</Typography>
                      <Typography component="p">
                        {requestData?.agentDetails?.countryCode
                          ? (requestData?.agentDetails?.countryCode.includes(
                              "+"
                            )
                              ? ""
                              : "+") + requestData?.agentDetails?.countryCode
                          : null}{" "}
                        {requestData?.agentDetails?.phone || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} className="view_box_list">
                <Typography className="detail_title" component="h2" mb={3}>
                  Assign SubAdmin
                </Typography>
                <Grid container spacing={3}>
                  <FormControl
                    sx={{
                      width: "40%",
                      paddingLeft: "24px",
                      marginTop: "20px",
                    }}
                  >
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={agent}
                      placeholder="Assign"
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default OrderDetails;
