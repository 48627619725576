import { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import {
  useChangeUserStatusMutation,
  useLazyGetAllUsersQuery,
  useLazyGetUserCsvQuery,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { AllUser } from "../../types/General";
import { isValidInput } from "../../utils/validations";
import { useLazyDeleteUserIdQuery } from "../../services/main";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import { Pagination, WarnModal } from "../../components";

const ManageUsers = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [getUserCsvMethod] = useLazyGetUserCsvQuery();
  const [deleteUserById] = useLazyDeleteUserIdQuery();
  const [userStatus] = useChangeUserStatusMutation();
  const [getAllUsersList, { isLoading }] = useLazyGetAllUsersQuery();

  const [allUsers, setAllUsers] = useState<AllUser[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState(1);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Users"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const getUsers = async () => {
    try {
      const response = await getAllUsersList({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllUsers(response?.data?.user || []);
        setTotalCount(response?.data?.userCount);
      } else {
        setAllUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await getUserCsvMethod({}).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteUserById({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        const res = await getAllUsersList({
          page: page,
          size: 10,
          query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (res?.statusCode === 200) {
          setAllUsers(res?.data?.user || []);
        } else {
          setAllUsers([]);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string) => {
    try {
      const response = await userStatus({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        const res = await getAllUsersList({
          page: page,
          size: 10,
          query: searchTerm,
        }).unwrap();
        if (res?.statusCode === 200) {
          setAllUsers(res?.data?.user || []);
        } else {
          setAllUsers([]);
        }
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage User</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={handleExportCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Phone no</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Plan Name</TableCell>
                  <TableCell>Plan Price</TableCell>
                  <TableCell>Posted Ads</TableCell>
                  <TableCell>Earnings</TableCell>
                  <TableCell>Account Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>

              {allUsers?.length ? (
                allUsers?.map((row, i) => {
                  return (
                    <TableBody key={row?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>{row?.userName || ""}</TableCell>
                        <TableCell>
                          {row?.countryCode
                            ? (row?.countryCode.includes("+") ? "" : "+") +
                              row?.countryCode
                            : null}{" "}
                          {row?.phone || "--"}
                        </TableCell>
                        <TableCell>{row?.email || "--"}</TableCell>
                        <TableCell>{row?.planName || "--"}</TableCell>
                        <TableCell>{row?.planPrice || "--"}</TableCell>
                        <TableCell>{row?.totalpost || 0}</TableCell>
                        <TableCell>{row?.totalearnings || 0}</TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            checked={!row?.isBlocked}
                            size="small"
                            onChange={() => updateStatus(row?._id)}
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <Tooltip title="View">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manageusers/userdetails/${row?._id}`
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                            {hidePermission?.isAdd ||
                            userData?.role === "ADMIN" ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manageusers/usersform/${row?._id}`
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}

                            {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={10}>
                    No User Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={allUsers}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="user"
      />
    </MainContainer>
  );
};

export default ManageUsers;
