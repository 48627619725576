import { END_POINTS } from "../constants/url";
import {
  Advertisement,
  AllUser,
  Auction,
  AuctionBidder,
  Category,
  PostSell,
  PostService,
  SellById,
  SubAdmin,
  Subscription,
  UserSell,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetAllUsersResponse = {
  statusCode: number;
  message: string;
  data: {
    userCount: number;
    user: AllUser[];
  };
};

type GetUserResponseById = {
  statusCode: number;
  message: string;
  data: {
    count: number;
    post: UserSell[];
    user: AllUser;
  };
};

type EditUserById = {
  email: string;
  userName: string;
  countryCode: string;
  phone: string;
  // address: string;
};

type GetAllSellResponse = {
  statusCode: number;
  message: string;
  data: {
    post: PostSell[];
    sellCount: number;
  };
};

type GetTokenParams = {
  size: number;
  page: number;
  query: string;
  status?: number;
};

type GetUsersParams = {
  user_id: string;
  size: number;
  page: number;
  query: string;
  status: number;
};

type PostSubscriptionResponse = {
  statusCode: number;
  message: string;
  data: Subscription;
};

type PostSubscriptionBody = {
  name: string;
  subscriptionDescription: string;
  oneMonth: string | number;
  sixMonth: string | number;
  oneYear: string | number;
};

type GetAllSubsciptionResponse = {
  message: string;
  statusCode: number;
  data: Subscription[];
};

type GetSellResponseById = {
  message: string;
  statusCode: number;
  data: SellById[];
};

type EditSellById = {
  posttitle: string;
  price: number | string;
  carPlatenumber: string;
  category: string;
  typeOfPrice: string;
};

type GetAllSellStatusResponse = {
  message: string;
  statusCode: number;
  data: {
    isBlocked: boolean;
  };
};

type GetAllCateResponse = {
  message: string;
  statusCode: number;
  data: Category[];
};

type GetAllAuctionRes = {
  message: string;
  statusCode: number;
  data: {
    auction: Auction[];
    count: number;
    message: string;
  };
};

type AuctionBody = {
  posttitel: string;
  price: number | string;
  carPlatenumber: string;
  category: string;
  meaningOfPlate: string;
  startingBid: string;
  endingBid?: string;
  reservePrice: string;
  minimumOffer: string;
  bidingStartTime: string;
  // bidingEndTime: string;
};

type GetSubAdminRes = {
  statusCode: number;
  message: string;
  data: {
    subAdmin: SubAdmin[];
    subAdminCount: number;
  };
};

type GetSubAdminResById = {
  statusCode: number;
  message: string;
  data: SubAdmin;
};

type SubAdminBody = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
  role: string;
  image?: string | undefined;
  permission: {
    label?: string | undefined;
    isView?: boolean;
    isAdd?: boolean;
    isDelete?: boolean;
  }[];
};

type ServiceRes = {
  data: PostService[];
  statusCode: number;
  message: string;
};

type AuctionResquestbody = {
  auctionId: string;
  status: number | string;
};

type AuctionResquestReserveBody = {
  auctionId: string;
  status: number | string;
  reservePrice: number | string;
};

type GetAuctionBidderRes = {
  message: string;
  statusCode: number;
  data: {
    bidders: AuctionBidder[];
  };
};

type AdvertisementRes = {
  data: {
    amount: number;
    count: number;
    advertisementPost: Advertisement[];
  };
  message: string;
  statusCode: number;
};

type AuctionTeaserBody = {
  sellingCommission: string;
  auctionStartDate: string;
  auctionEndDate: string;
  auctionTeaser: string;
};
export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<GetAllUsersResponse, GetTokenParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.get_all_user +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getUserId: builder.query<GetUserResponseById, GetUsersParams>({
      query: ({ user_id, page, query, size, status }) => ({
        url: `${END_POINTS.user_by_id}/${user_id}?status=${status}&page=${page}&limit=${size}&search=${query}`,
        method: "GET",
      }),
    }),
    updateUserDetailById: builder.mutation<
      GetUserResponseById,
      { user_id: string; body: EditUserById }
    >({
      query: ({ user_id, body }) => ({
        url: `${END_POINTS.edit_user_by_id}${user_id}/`,
        method: "PUT",
        body,
      }),
    }),
    changeUserStatus: builder.mutation<
      GetAllSellStatusResponse,
      { user_id: string }
    >({
      query: ({ user_id }) => ({
        url: `${END_POINTS.user_status}${user_id}/`,
        method: "PUT",
      }),
    }),
    getUserCsv: builder.query<
      {
        statusCode: number;
        message: number;
        data: string;
      },
      unknown
    >({
      query: () => ({
        url: END_POINTS.user_csv,
        method: "GET",
      }),
    }),
    deleteUserId: builder.query<GetUserResponseById, { user_id: string }>({
      query: ({ user_id }) => ({
        url: `${END_POINTS.delete_user_by_id}/${user_id}`,
        method: "DELETE",
      }),
    }),
    getAllSale: builder.query<GetAllSellResponse, GetTokenParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.get_all_sell +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getPostService: builder.query<ServiceRes, {}>({
      query: () => ({
        url: `${END_POINTS.carPlate_service}`,
        method: "GET",
      }),
    }),
    getSellId: builder.query<GetSellResponseById, { sell_id: string }>({
      query: ({ sell_id }) => ({
        url: `${END_POINTS.sell_By_id}/${sell_id}`,
        method: "GET",
      }),
    }),
    deleteSaleById: builder.query<GetAllSellResponse, { user_id: string }>({
      query: ({ user_id }) => ({
        url: `${END_POINTS.get_all_sell}/${user_id}`,
        method: "DELETE",
      }),
    }),
    updateSellById: builder.mutation<
      GetUserResponseById,
      { sell_id: string; body: EditSellById }
    >({
      query: ({ sell_id, body }) => ({
        url: `${END_POINTS.get_all_sell}/${sell_id}/`,
        method: "PUT",
        body,
      }),
    }),
    changeSellStatus: builder.mutation<
      GetAllSellStatusResponse,
      { sell_id: string }
    >({
      query: ({ sell_id }) => ({
        url: `${END_POINTS.sell_status}${sell_id}/`,
        method: "PUT",
      }),
    }),
    getAllAuction: builder.query<GetAllAuctionRes, GetTokenParams>({
      query: ({ status, size, page, query }) => ({
        url:
          END_POINTS.get_all_auction +
          "?status=" +
          status +
          "&limit=" +
          size +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getAuctionById: builder.query<GetAllAuctionRes, { auction_id: string }>({
      query: ({ auction_id }) => ({
        url: `${END_POINTS.get_all_auction}/${auction_id}`,
        method: "GET",
      }),
    }),
    updateAuctionById: builder.mutation<
      GetAllAuctionRes,
      { auction_id: string; body: AuctionBody }
    >({
      query: ({ auction_id, body }) => ({
        url: `${END_POINTS.get_all_auction}/${auction_id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateAuctionStatus: builder.mutation<
      GetAllAuctionRes,
      AuctionResquestbody
    >({
      query: (body) => ({
        url: END_POINTS.update_auction_status,
        method: "POST",
        body,
      }),
    }),
    addReservePrice: builder.mutation<
      GetAllAuctionRes,
      AuctionResquestReserveBody
    >({
      query: (body) => ({
        url: END_POINTS.update_auction_status,
        method: "POST",
        body,
      }),
    }),
    deleteAuctionById: builder.query<GetAllAuctionRes, { auction_id: string }>({
      query: ({ auction_id }) => ({
        url: `${END_POINTS.get_all_auction}/${auction_id}`,
        method: "DELETE",
      }),
    }),
    changeAuctionStatus: builder.mutation<
      GetAllAuctionRes,
      { auction_id: string }
    >({
      query: ({ auction_id }) => ({
        url: `${END_POINTS.auction_status}${auction_id}/`,
        method: "PUT",
      }),
    }),
    getBiderByAuctionId: builder.query<
      GetAuctionBidderRes,
      { auction_id: string }
    >({
      query: ({ auction_id }) => ({
        url: `${END_POINTS.getBiderByAuctionId}/${auction_id}`,
        method: "GET",
      }),
    }),
    getAllOffer: builder.query<GetAllSellResponse, GetTokenParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.get_all_offer +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    viewAllOfferById: builder.query<
      GetAllSellResponse,
      GetTokenParams & { Offer_Id: string }
    >({
      query: ({ size, page, query, Offer_Id }) => ({
        url:
          END_POINTS.get_offer_byId +
          Offer_Id +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    addSubscription: builder.mutation<
      PostSubscriptionResponse,
      PostSubscriptionBody
    >({
      query: (body) => ({
        url: END_POINTS.subscription,
        method: "POST",
        body,
      }),
    }),
    updateSubscription: builder.mutation<
      GetUserResponseById,
      { sub_id: string; body: PostSubscriptionBody }
    >({
      query: ({ sub_id, body }) => ({
        url: `${END_POINTS.subscription}/${sub_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getAllSubscription: builder.query<GetAllSubsciptionResponse, {}>({
      query: () => ({
        url: END_POINTS.subscription,
        method: "GET",
      }),
    }),

    deleteSubscriptionById: builder.query<
      GetUserResponseById,
      { subscription_id: string }
    >({
      query: ({ subscription_id }) => ({
        url: `${END_POINTS.subscription}/${subscription_id}`,
        method: "DELETE",
      }),
    }),
    getAllAdvertisement: builder.query<AdvertisementRes, GetTokenParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.advertisementPost +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    addAdvertisementAmt: builder.mutation<
      AdvertisementRes,
      { advertisementAmount: number }
    >({
      query: (body) => ({
        url: END_POINTS.update_profile,
        method: "PUT",
        body,
      }),
    }),

    //add sell price

    addSellPriceAmount: builder.mutation<
      AdvertisementRes,
      { sellPriceAmount: number }
    >({
      query: (body) => ({
        url: END_POINTS.update_profile,
        method: "PUT",
        body,
      }),
    }),
    //
    getAdvertisementById: builder.query<
      {
        message: string;
        statusCode: number;
        data: AdvertisementRes;
      },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.advertisementPost}/${id}`,
        method: "GET",
      }),
    }),
    getSubscriptionById: builder.query<
      {
        message: string;
        statusCode: number;
        data: Subscription;
      },
      { sub_id: string }
    >({
      query: ({ sub_id }) => ({
        url: `${END_POINTS.sub_by_id}/${sub_id}`,
        method: "GET",
      }),
    }),
    changeSubscriptionStatus: builder.mutation<
      GetAllSubsciptionResponse,
      { sub_id: string }
    >({
      query: ({ sub_id }) => ({
        url: `${END_POINTS.sub_status}${sub_id}/`,
        method: "PUT",
      }),
    }),
    getAllCate: builder.query<GetAllCateResponse, {}>({
      query: () => ({
        url: END_POINTS.category,
        method: "GET",
      }),
    }),
    deleteCateById: builder.query<GetAllCateResponse, { cate_id: string }>({
      query: ({ cate_id }) => ({
        url: `${END_POINTS.category}/${cate_id}`,
        method: "DELETE",
      }),
    }),
    addCategory: builder.mutation<
      { message: string; statusCode: number; data: Category },
      { categoryName: string }
    >({
      query: (body) => ({
        url: END_POINTS.category,
        method: "POST",
        body,
      }),
    }),
    updateCate: builder.mutation<
      { message: string; statusCode: number; data: Category },
      { cate_id: string; body: { categoryName: string } }
    >({
      query: ({ cate_id, body }) => ({
        url: `${END_POINTS.category}/${cate_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getCateById: builder.query<
      {
        message: string;
        statusCode: number;
        data: Category;
      },
      { cate_id: string }
    >({
      query: ({ cate_id }) => ({
        url: `${END_POINTS.cate_by_id}/${cate_id}`,
        method: "GET",
      }),
    }),
    changeCateStatus: builder.mutation<GetAllCateResponse, { cate_id: string }>(
      {
        query: ({ cate_id }) => ({
          url: `${END_POINTS.cate_status}${cate_id}/`,
          method: "PUT",
        }),
      }
    ),
    getAllSubAdmin: builder.query<GetSubAdminRes, GetTokenParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.subAdmin +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    deleteSubAdmin: builder.query<GetSubAdminRes, { subAmin_id: string }>({
      query: ({ subAmin_id }) => ({
        url: `${END_POINTS.subAdmin}/${subAmin_id}`,
        method: "DELETE",
      }),
    }),
    changeSubAdminStatus: builder.mutation<
      GetSubAdminRes,
      { subAmin_id: string }
    >({
      query: ({ subAmin_id }) => ({
        url: `${END_POINTS.subAdmin_status}/${subAmin_id}/`,
        method: "PUT",
      }),
    }),
    addSubAdmin: builder.mutation<GetSubAdminRes, SubAdminBody>({
      query: (body) => ({
        url: END_POINTS.subAdmin,
        method: "POST",
        body,
      }),
    }),
    getSubAdminById: builder.query<GetSubAdminResById, { subAmin_id: string }>({
      query: ({ subAmin_id }) => ({
        url: `${END_POINTS.subadmin_by_id}/${subAmin_id}`,
        method: "GET",
      }),
    }),
    updateSubAdminById: builder.mutation<
      GetSubAdminResById,
      { subAmin_id: string; body: SubAdminBody }
    >({
      query: ({ subAmin_id, body }) => ({
        url: `${END_POINTS.subAdmin}/${subAmin_id}/`,
        method: "PUT",
        body,
      }),
    }),

    addAuctionTeaser: builder.mutation<AdvertisementRes, AuctionTeaserBody>({
      query: (body) => ({
        url: END_POINTS.update_profile,
        method: "PUT",
        body,
      }),
    }),
  }),
});
export const {
  useLazyGetAllUsersQuery,
  useLazyGetUserIdQuery,
  useLazyDeleteUserIdQuery,
  useUpdateUserDetailByIdMutation,
  useLazyGetAllSaleQuery,
  useLazyDeleteSaleByIdQuery,
  useLazyGetAllAuctionQuery,
  useLazyGetBiderByAuctionIdQuery,
  useAddSubscriptionMutation,
  useLazyGetAllSubscriptionQuery,
  useLazyDeleteSubscriptionByIdQuery,
  useLazyGetSellIdQuery,
  useUpdateSellByIdMutation,
  useChangeSellStatusMutation,
  useChangeUserStatusMutation,
  useLazyGetSubscriptionByIdQuery,
  useUpdateSubscriptionMutation,
  useChangeSubscriptionStatusMutation,
  useLazyGetAllCateQuery,
  useLazyDeleteCateByIdQuery,
  useAddCategoryMutation,
  useUpdateCateMutation,
  useLazyGetCateByIdQuery,
  useChangeCateStatusMutation,
  useLazyGetAuctionByIdQuery,
  useUpdateAuctionByIdMutation,
  useLazyDeleteAuctionByIdQuery,
  useChangeAuctionStatusMutation,
  useLazyGetAllSubAdminQuery,
  useLazyDeleteSubAdminQuery,
  useChangeSubAdminStatusMutation,
  useAddSubAdminMutation,
  useLazyGetSubAdminByIdQuery,
  useUpdateSubAdminByIdMutation,
  useLazyGetUserCsvQuery,
  useLazyGetPostServiceQuery,
  useUpdateAuctionStatusMutation,
  useLazyGetAllOfferQuery,
  useLazyViewAllOfferByIdQuery,
  useLazyGetAllAdvertisementQuery,
  useLazyGetAdvertisementByIdQuery,
  useAddAdvertisementAmtMutation,
  useAddReservePriceMutation,
  useAddSellPriceAmountMutation,
  useAddAuctionTeaserMutation,
} = mainApi;
