/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  Button,
  Modal,
  Grid,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useAddReservePriceMutation,
  useAddSellPriceAmountMutation,
  useChangeAuctionStatusMutation,
  useChangeSellStatusMutation,
  useLazyDeleteAuctionByIdQuery,
  useLazyDeleteSaleByIdQuery,
  useLazyGetAllAuctionQuery,
  useLazyGetAllOfferQuery,
  useLazyGetAllSaleQuery,
  useUpdateAuctionStatusMutation,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { Auction, PostSell, PostStatus, status } from "../../types/General";
import { isNumber, isValidInput } from "../../utils/validations";
import moment from "moment";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { generateResponsiveStyle } from "../../utils/ModalStyle";
import Person4Icon from "@mui/icons-material/Person4";
import { Pagination, WarnModal } from "../../components";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManagePosts = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const style = generateResponsiveStyle();

  const [getPostById, getPostByIdData] = useLazyGetAllSaleQuery();
  const [getAllAuction, { isLoading }] = useLazyGetAllAuctionQuery();
  const [deleteSellById] = useLazyDeleteSaleByIdQuery();
  const [deleteAuctionById] = useLazyDeleteAuctionByIdQuery();
  const [sellStatus] = useChangeSellStatusMutation();
  const [auctionStatus] = useChangeAuctionStatusMutation();
  const [requestStatusMutation] = useUpdateAuctionStatusMutation();
  const [getOfferMutation] = useLazyGetAllOfferQuery();
  const [addReverseMutaion] = useAddReservePriceMutation();
  const [addSellPrice, addSellPriceData] = useAddSellPriceAmountMutation();

  const [value, setValue] = useState(state === "auction" ? 1 : 0);
  const [open, setOpen] = useState(false);
  const [acceptOpen, setAcceptOpen] = useState(false);
  const [error, setError] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedPrice, setSelectedPrice] = useState<any>("");
  const [auctionStartDate, setAuctionStartDate] = useState<any>("");
  const [auctionEndDate, setAuctionEndDate] = useState<any>("");

  const [reservePrice, setReservePrice] = useState<string>("");
  const [startAuctionTime, setStartAuctionTime] = useState<string>("");
  const [startAuctionTimeUtc, setStartAuctionTimeUtc] = useState<string>("");
  const [endAuctionTime, setEndAuctionTime] = useState<string>("");
  const [endAuctionTimeUtc, setEndAuctionTimeUtc] = useState<string>("");

  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalCount1, setTotalCount1] = useState<number>(0);
  const [totalCountoffer, setTotalCountOffer] = useState<number>(0);
  const [postSell, setPostSell] = useState<PostSell[]>([]);
  const [auction, setAuction] = useState<Auction[]>([]);
  const [offer, setOffer] = useState<PostSell[]>([]);
  const [value3, setValue3] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pageOffer, setPageOffer] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");
  const [page1, setPage1] = useState(1);
  const [debouncedSearchTerm1, setDebouncedSearchTerm1] = useState("");
  const [searchTermOffer, setSearchTermOffer] = useState("");
  const [debouncedSearchTermOffer, setDebouncedSearchTermOffer] = useState("");
  const [sellPrice, setSellPrice] = useState<any>("");
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  //auction time
  const handleStartAuctionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartAuctionTime(event.target.value);
    const selectedTime = event.target.value;
    const [hours, minutes] = selectedTime.split(":").map(Number);

    // Create a new Date object with the current date and the selected time
    const currentDate = new Date();
    currentDate.setUTCHours(hours);
    currentDate.setUTCMinutes(minutes);
    const utcTimeString = currentDate.toISOString();
    setStartAuctionTimeUtc(utcTimeString);
  };

  const handleEndAuctionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEndAuctionTime(event.target.value);
    const selectedTime = event.target.value;
    const [hours, minutes] = selectedTime.split(":").map(Number);

    // Create a new Date object with the current date and the selected time
    const currentDate = new Date();
    currentDate.setUTCHours(hours);
    currentDate.setUTCMinutes(minutes);
    const utcTimeString = currentDate.toISOString();
    setEndAuctionTimeUtc(utcTimeString);
  };
  const handleRequestStatus = async (
    event: SelectChangeEvent,
    id: string,
    price: number,
    bidingStartTime: string,
    bidingEndTime: string
  ) => {
    if (Number(event.target.value) === 1) {
      setAcceptOpen(true);
      setSelectedId(id);
      setSelectedPrice(price);
      setAuctionStartDate(bidingStartTime);
      setAuctionEndDate(bidingEndTime);
    } else {
      const body = {
        auctionId: id,
        status: Number(event.target.value),
        reservePrice: "0",
      };
      try {
        const response = await requestStatusMutation(body).unwrap();
        if (response?.statusCode === 200) {
          let idx = -1;
          idx = auction?.findIndex((ele) => ele?._id === id);
          if (idx > -1) {
            const updatedData = auction.map((x) => {
              if (x._id === id) {
                return {
                  ...x,
                  status: event.target.value,
                };
              }
              return x;
            });
            setAuction(updatedData);
            getAllAuctionFunction();
          }
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  let totalPages = Math.ceil(totalCount / 10);
  let totalPages1 = Math.ceil(totalCount1 / 10);
  let totalPagesOffer = Math.ceil(totalCountoffer / 10);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChange3 = (event: React.SyntheticEvent, newValue: number) => {
    setValue3(newValue);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageChange1 = (newPage: number) => {
    setPage1(newPage);
  };

  const onPageChangeOffer = (newPage: number) => {
    setPageOffer(newPage);
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Car Plate Posts"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const getAllPost = async () => {
    try {
      const response = await getPostById({
        page: page1,
        size: 10,
        query: debouncedSearchTerm1.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setPostSell(response?.data?.post || []);
        setTotalCount1(response?.data?.sellCount);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getAllAuctionFunction = async () => {
    try {
      const response = await getAllAuction({
        status: value3,
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setAuction(response?.data?.auction || []);
        setTotalCount(response?.data?.count);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getAllOffer = async () => {
    try {
      const response = await getOfferMutation({
        page: pageOffer,
        size: 10,
        query: debouncedSearchTermOffer.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setOffer(response?.data?.post || []);
        setTotalCountOffer(response?.data?.sellCount);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteSellById({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        try {
          const response = await getPostById({
            page: page1,
            size: 10,
            query: debouncedSearchTerm1.trim(),
          }).unwrap();
          if (response?.statusCode === 200) {
            setPostSell(response?.data?.post || []);
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleAuctionDelete = async (id: string) => {
    try {
      const response = await deleteAuctionById({ auction_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        try {
          const response = await getAllAuction({
            status: value3,
            page: page,
            size: 10,
            query: debouncedSearchTerm.trim(),
          }).unwrap();
          if (response?.statusCode === 200) {
            setAuction(response?.data?.auction || []);
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateSellStatus = async (id: string) => {
    try {
      const response = await sellStatus({ sell_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        try {
          const response = await getPostById({
            page: page1,
            size: 10,
            query: debouncedSearchTerm1.trim(),
          }).unwrap();
          if (response?.statusCode === 200) {
            setPostSell(response?.data?.post || []);
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateAuctionStatus = async (id: string) => {
    try {
      const response = await auctionStatus({ auction_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        try {
          const response = await getAllAuction({
            status: value3,
            page: page,
            size: 10,
            query: debouncedSearchTerm.trim(),
          }).unwrap();
          if (response?.statusCode === 200) {
            setAuction(response?.data?.auction || []);
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddReservePrice = async (id: string) => {
    const body = {
      auctionId: id,
      status: 1,
      reservePrice: reservePrice,
      startTime: startAuctionTime,
      endTime: endAuctionTime,
    };
    console.log(body, "bodybody");

    try {
      const response = await addReverseMutaion(body).unwrap();
      if (response?.statusCode === 200) {
        setAcceptOpen(false);
        setReservePrice("");
        getAllAuctionFunction();
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getAllAuctionFunction();
  }, [debouncedSearchTerm, value3, page]);

  useEffect(() => {
    getAllPost();
  }, [debouncedSearchTerm1, page1]);

  useEffect(() => {
    getAllOffer();
  }, [debouncedSearchTermOffer, pageOffer]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm1(searchTerm1);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm1]);

  const handleAddSalePrice = async () => {
    try {
      const response = await addSellPrice({
        sellPriceAmount: Number(sellPrice),
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message);
      } else {
        showError(response?.message || "");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    setSellPrice(userData?.sellPriceAmount);
  }, [userData]);

  return (
    <MainContainer>
      <Loader
        isLoad={
          isLoading || getPostByIdData?.isLoading || addSellPriceData?.isLoading
        }
      />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Car Plate Posts</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Posts for Sale" {...a11yProps(0)} />
              <Tab label="Posts for Auction" {...a11yProps(1)} />
              <Tab label="Post for an Offer" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <Box className="cards_header">
                  <Box className="flexad">
                    <div>
                      <Typography className="custom_label">
                        Add Sell Price
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type={"email"}
                        name="email"
                        variant="outlined"
                        fullWidth
                        placeholder="Amount"
                        id="email"
                        style={{ width: 250 }}
                        inputProps={{ maxLength: 10 }}
                        value={sellPrice}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            setSellPrice(val.target.value);
                          }
                        }}
                      />
                    </div>
                    <Button
                      disabled={!sellPrice}
                      className="btn btn_primary"
                      onClick={handleAddSalePrice}
                    >
                      Add
                    </Button>
                  </Box>
                  <SearchBar
                    value={searchTerm1}
                    searchTerm={searchTerm1}
                    onCross={() => setSearchTerm1("")}
                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                    onChange={(val: any) => {
                      if (isValidInput(val.target.value)) {
                        setSearchTerm1(val.target.value);
                      }
                    }}
                  />
                </Box>

                <Box className="cards_header_right">
                  {/* <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-posts/addPostForSell")}
                  >
                    Add Post
                  </Button> */}
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Customer Details</TableCell>
                      <TableCell>Post Title</TableCell>
                      <TableCell>Car Plate Number</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Price Type</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {postSell?.length ? (
                    postSell?.map((row, i) => {
                      return (
                        <TableBody key={row?._id}>
                          <TableRow>
                            <TableCell align="center">
                              {(page1 - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell>
                              {row?.CustomerDetails?.fullName || "--"} <br />
                              {row?.CustomerDetails?.email || "--"} <br />
                              {row?.CustomerDetails?.countryCode
                                ? (row?.CustomerDetails?.countryCode.includes(
                                    "+"
                                  )
                                    ? ""
                                    : "+") + row?.CustomerDetails?.countryCode
                                : null}
                              {row?.CustomerDetails?.phone || "--"}
                            </TableCell>
                            <TableCell>{row?.posttitle || "--"}</TableCell>
                            <TableCell>{row?.carPlatenumber || "--"}</TableCell>
                            <TableCell style={{ textTransform: "capitalize" }}>
                              {row?.category || "--"}
                            </TableCell>
                            <TableCell>{row?.typeOfPrice || "--"}</TableCell>
                            <TableCell>{row?.price || "0"} SAR</TableCell>
                            <TableCell>
                              <Switch
                                {...label}
                                checked={!row?.isBlocked}
                                size="small"
                                onChange={() => updateSellStatus(row?._id)}
                              />
                            </TableCell>

                            <TableCell>
                              <Box className="table_actions">
                                <Tooltip title="View">
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/manage-posts/viewSale/${row?._id}`
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>

                                {hidePermission?.isAdd ||
                                userData?.role === "ADMIN" ? (
                                  <Tooltip title="Edit">
                                    <IconButton
                                      onClick={() =>
                                        navigate(
                                          `/manage-posts/editSale/${row?._id}`
                                        )
                                      }
                                    >
                                      <ModeEditIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}

                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <Tooltip title="Delete">
                                    <IconButton
                                      onClick={() => {
                                        setOpen(true);
                                        setSelectedId(row?._id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell align="center" colSpan={9}>
                        No Data Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <Pagination
                setPage={setPage1}
                module={postSell}
                page={page1}
                onPageChange={onPageChange1}
                totalPages={totalPages1}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                {/* ll */}
                <Box sx={{ width: "100%", marginBottom: 3 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value3}
                      onChange={handleChange3}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Pending" {...a11yProps(0)} />
                      <Tab label="Upcoming" {...a11yProps(1)} />
                      <Tab label="Ongoing" {...a11yProps(2)} />
                      <Tab label="Past" {...a11yProps(3)} />
                    </Tabs>
                  </Box>
                </Box>
                {/* // */}
                <br />
              </Box>
              <SearchBar
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Customer Details</TableCell>
                      <TableCell>Post Title</TableCell>
                      <TableCell>Car Plate Number</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Starting Bid</TableCell>
                      {/* <TableCell>Reserve Price</TableCell> */}
                      <TableCell>Minimum Offer</TableCell>
                      <TableCell>Bidding Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Request Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {auction?.length ? (
                    auction?.map((row, i) => {
                      return (
                        <TableBody key={row?._id}>
                          <TableRow>
                            <TableCell align="center">
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell>
                              {row?.userfullName || "--"} <br />
                              {row?.email || "--"} <br />
                              {row?.countryCode
                                ? (row?.countryCode.includes("+") ? "" : "+") +
                                  row?.countryCode
                                : null}
                              {row?.phone || "--"}
                            </TableCell>
                            <TableCell>{row?.posttitle || "--"}</TableCell>
                            <TableCell align="center">
                              {row?.carPlatenumber || "--"}
                            </TableCell>
                            <TableCell>{row?.category || "--"}</TableCell>
                            <TableCell align="center">
                              {row?.price || 0} SAR
                            </TableCell>
                            <TableCell align="center">
                              {row?.startingBid || "0"} SAR
                            </TableCell>
                            {/* <TableCell align="center">
                              {row?.reservePrice || "0"} SAR
                            </TableCell> */}
                            <TableCell align="center">
                              {row?.minimumOffer || "0"} SAR
                            </TableCell>
                            <TableCell>
                              {moment(row?.bidingStartTime).format("ll")}
                              {/* {moment(row?.bidingStartTime).format("LT") || ""}{" "} */}
                            </TableCell>
                            <TableCell align="center">
                              <Switch
                                {...label}
                                checked={!row?.isBlocked}
                                size="small"
                                onChange={() => updateAuctionStatus(row?._id)}
                              />
                            </TableCell>
                            <TableCell align="center">
                              {value3 === 0 ? (
                                <FormControl fullWidth>
                                  <Select
                                    className="select_div"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={row?.status > 0 ? row?.status : ""}
                                    onChange={(val) =>
                                      handleRequestStatus(
                                        val,
                                        row?._id,
                                        row?.price,
                                        row?.bidingStartTime,
                                        row?.bidingEndTime
                                      )
                                    }
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    displayEmpty
                                  >
                                    <MenuItem value="" disabled>
                                      Select
                                    </MenuItem>
                                    {PostStatus?.map((item, i) => (
                                      <MenuItem key={i} value={item?.id}>
                                        {item?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                <p>{status[row?.status]}</p>
                              )}
                            </TableCell>
                            <TableCell>
                              <Box className="table_actions">
                                {value3 === 2 || value3 === 3 ? (
                                  <Tooltip title="Bidders">
                                    <IconButton
                                      onClick={() =>
                                        navigate(
                                          `/auction-bidding/${row?._id}`,
                                          {
                                            state: value3 === 3 ? "yes" : "no",
                                          }
                                        )
                                      }
                                    >
                                      <Person4Icon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}

                                <Tooltip title="View">
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/manage-posts/viewAuction/${row?._id}`
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>

                                {hidePermission?.isAdd ||
                                userData?.role === "ADMIN" ? (
                                  <Tooltip title="Edit">
                                    <IconButton
                                      onClick={() =>
                                        navigate(
                                          `/manage-posts/editAuction/${row?._id}`
                                        )
                                      }
                                    >
                                      <ModeEditIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}

                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <Tooltip title="Delete">
                                    <IconButton
                                      onClick={() => {
                                        setOpen(true);
                                        setSelectedId(row?._id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell align="center" colSpan={13}>
                        No Data Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <Pagination
                setPage={setPage}
                module={auction}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTermOffer}
                  searchTerm={searchTermOffer}
                  onCross={() => setSearchTerm1("")}
                  setDebouncedSearchTerm={setDebouncedSearchTermOffer}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTermOffer(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Post Name</TableCell>
                      <TableCell>Car Plate Number</TableCell>
                      <TableCell>Owner Details</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Offer</TableCell>
                    </TableRow>
                  </TableHead>
                  {offer?.length ? (
                    offer?.map((row, i) => {
                      return (
                        <TableBody key={row?._id}>
                          <TableRow>
                            <TableCell align="center">
                              {(pageOffer - 1) * 10 + i + 1}
                            </TableCell>

                            <TableCell>{row?.posttitle || "--"}</TableCell>
                            <TableCell>{row?.carPlatenumber || "--"}</TableCell>
                            <TableCell>
                              {row?.CustomerDetails?.fullName || "--"}
                              <br />
                              {row?.CustomerDetails?.email || "--"}
                              <br />
                              {row?.CustomerDetails.countryCode
                                ? (row?.CustomerDetails.countryCode.includes(
                                    "+"
                                  )
                                    ? ""
                                    : "+") + row?.CustomerDetails.countryCode
                                : null}
                              {row?.CustomerDetails.phone || "--"}
                            </TableCell>
                            <TableCell>
                              {moment(row?.createdAt).format("MMM Do YY") || ""}
                            </TableCell>
                            <TableCell>
                              <Button
                                size="large"
                                className="btn btn_primary sm"
                                onClick={() =>
                                  navigate(`/view-offer/${row?._id}`)
                                }
                              >
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell align="center" colSpan={9}>
                        No Data Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <Pagination
                setPage={setPageOffer}
                module={offer}
                page={pageOffer}
                onPageChange={onPageChangeOffer}
                totalPages={totalPagesOffer}
              />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => {
          if (value === 0) {
            handleDelete(selectedId);
          } else {
            handleAuctionDelete(selectedId);
          }
        }}
        name="post"
      />
      <Modal
        open={acceptOpen}
        onClose={() => setAcceptOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="custom_label">Price</Typography>
              <Typography className="custom_label">
                {selectedPrice} SAR
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="custom_label">
                Add Reserve Price(%)
              </Typography>
              <TextField
                className="text_field"
                hiddenLabel
                type={"text"}
                name="serviceFee"
                variant="outlined"
                fullWidth
                placeholder="Price"
                id="Reserve Price"
                value={reservePrice}
                onChange={(val) => {
                  if (
                    val.target.value === " " ||
                    val.target.value === "." ||
                    val.target.value === "0"
                  ) {
                  } else if (isNumber(val.target.value)) {
                    setReservePrice(val.target.value);
                  }
                }}
                inputProps={{ maxLength: 10 }}
              />
              {error && Number(reservePrice) >= Number(selectedPrice) ? (
                <h6 className="err_msg">
                  You cannot set reserve price more than plate price
                </h6>
              ) : (
                ""
              )}
              {error && Number(reservePrice) > 100 ? (
                <h6 className="err_msg">
                  Please enter a number between 0 and 100.
                </h6>
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography className="custom_label">Auction Dates</Typography>
              <Typography className="custom_label">
                {moment(auctionStartDate).format("YYYY-MM-DD")} to{" "}
                {moment(auctionEndDate).format("YYYY-MM-DD")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="custom_label">
                Auction Start Time
              </Typography>
              <TextField
                className="text_field"
                hiddenLabel
                type="time"
                name="startAuctionTime"
                variant="outlined"
                fullWidth
                id="startAuctionTime"
                value={startAuctionTime}
                onChange={handleStartAuctionChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="custom_label">Auction End Time</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                type="time"
                variant="outlined"
                fullWidth
                name="endAuctionTime"
                id="endAuctionTime"
                value={endAuctionTime}
                onChange={handleEndAuctionChange}
              />
            </Grid>
          </Grid>
          <div className="form_btn">
            <Button
              size="large"
              type="submit"
              disabled={
                !reservePrice ||
                Number(reservePrice) >= Number(selectedPrice) ||
                Number(reservePrice) > 100
              }
              className="btn btn_primary"
              onClick={() => {
                setError(true);
                handleAddReservePrice(selectedId);
              }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </MainContainer>
  );
};

export default ManagePosts;
