import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNumber, isString } from "../../utils/validations";
import { useEffect, useState } from "react";
import {
  useLazyGetAllCateQuery,
  useLazyGetPostServiceQuery,
  useLazyGetSellIdQuery,
  useUpdateSellByIdMutation,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { Category, PostService, SellById } from "../../types/General";

const AddPostForSell = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editSell, { isLoading }] = useUpdateSellByIdMutation();
  const [allCategory] = useLazyGetAllCateQuery();
  const [getSellId, getSellIdData] = useLazyGetSellIdQuery();
  const [postService] = useLazyGetPostServiceQuery();

  const [priceType, setPriceType] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState(false);
  const [sellData, setSellData] = useState<SellById | null>(null);
  const [category1, setCategory1] = useState<Category[]>([]);
  const [service, setService] = useState<PostService[]>([]);
  const [serviceId, setServiceId] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent) => {
    setPriceType(event.target.value as string);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    console.log(event.target, ">>>><<<<<<<");

    setCategory(event.target.value as string);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      posttitel: sellData?.posttitle || "",
      price: sellData?.price || "",
      category: "",
      carPlatenumber: sellData?.carPlatenumber || "",
      priceType: "",
    },
    validationSchema: Yup.object({
      posttitel: Yup.string()
        .required("This field is required")
        .max(3, "Maximum 3 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      carPlatenumber: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      price: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 digits are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        posttitle: formik.values.posttitel,
        price: formik.values.price,
        carPlatenumber: formik.values.carPlatenumber,
        category: category,
        typeOfPrice: priceType,
        serviceId: serviceId,
      };
      try {
        const response = await editSell({ body, sell_id: `${id}` }).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message || "");
          navigate("/manage-posts");
        }
      } catch (error: any) {
        console.log(error);
      }
    },
  });

  const getSellDetailById = async (id: string) => {
    try {
      const response = await getSellId({ sell_id: id }).unwrap();
      if (response?.statusCode === 200) {
        if (response?.data?.length) {
          setSellData(response?.data[0] || []);
          setServiceId(response?.data[0]?.serviceId);
          setCategory(response?.data[0]?.categoryData?._id);
          setPriceType(response?.data[0]?.typeOfPrice || "");
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategory1(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getPostService = async () => {
    try {
      const response = await postService({}).unwrap();
      if (response?.statusCode === 200) {
        setService(response?.data || []);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getSellDetailById(id);
      getPostService();
    }
  }, []);

  useEffect(() => {
    getAllCate();
  }, []);

  return (
    <>
      <Loader isLoad={isLoading || getSellIdData?.isLoading} />
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Edit Post For Sale</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-posts");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Post Title</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="posttitel"
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 3 }}
                      placeholder="Post Title"
                      id="posttitel"
                      onBlur={formik.handleBlur}
                      value={formik.values.posttitel}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.posttitel && formik.errors.posttitel
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Car Plate Number
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="carPlatenumber"
                      variant="outlined"
                      fullWidth
                      placeholder="Car Plate Number"
                      id="carPlatenumber"
                      onBlur={formik.handleBlur}
                      value={formik.values.carPlatenumber}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.carPlatenumber &&
                        formik.errors.carPlatenumber
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Category</Typography>
                    <FormControl fullWidth>
                      <Select
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {category1?.length
                          ? category1?.map((item) => (
                              <MenuItem key={item?._id} value={item?._id}>
                                {item?.categoryName || ""}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {error && !category ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Price Type</Typography>
                    <FormControl fullWidth>
                      <Select
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={priceType}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="Fixed">Fixed</MenuItem>
                        <MenuItem value="Negotiate">Negotiable</MenuItem>
                        <MenuItem value="FixedAndNegotiate">
                          Fixed but negotiable
                        </MenuItem>
                      </Select>
                      {error && !priceType ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Price</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="price"
                      variant="outlined"
                      fullWidth
                      placeholder="Price of the car plate"
                      id="price"
                      onBlur={formik.handleBlur}
                      value={formik.values.price}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={formik.touched.price && formik.errors.price}
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    {service?.length
                      ? service?.map((item) => (
                          <>
                            <FormControlLabel
                              key={item?._id}
                              checked={serviceId?.includes(item?._id)}
                              control={<Checkbox />}
                              label={item?.serviceName || ""}
                              onChange={(e) => {
                                if (serviceId.includes(item?._id)) {
                                  setServiceId((serviceId) =>
                                    serviceId.filter((s) => s !== item?._id)
                                  );
                                } else {
                                  setServiceId((serviceId) => [
                                    ...serviceId,
                                    item?._id,
                                  ]);
                                }
                              }}
                            />
                            <br />
                          </>
                        ))
                      : null}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddPostForSell;
