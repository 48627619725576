/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Container,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { useLazyGetUserQuery, usePostLoginMutation } from "../../services/auth";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  showError,
  showToast,
} from "../../constants";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";

const Login = () => {
  const fcmToken = getFromStorage(STORAGE_KEYS.fcmToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getUser] = useLazyGetUserQuery();
  const [loginMutation, { isLoading }] = usePostLoginMutation();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getUser({}).unwrap();
      if (result?.statusCode === 200 && token) {
        dispatch(
          setCredentials({
            user: result?.data,
            token: token,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string().required("Password is required!"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        key: formik.values.email,
        password: formik.values.password,
        deviceType: "WEB",
        deviceToken: fcmToken || null,
      };

      try {
        const response = await loginMutation(body).unwrap();
        if (response?.statusCode === 200) {
          showToast("Login Successfully.");
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );
          getUserDetails();
          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }
          navigate("/dashboard");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.key);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center" }}>
            <Typography variant="h5">Log in to Lowhate Admin</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Email Address</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Email Address"
                fullWidth
                name="email"
                type="email"
                variant="outlined"
                id="email"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Password</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Password"
                fullWidth
                name="password"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box className="remember_box">
                <FormControlLabel
                  control={<Checkbox />}
                  checked={formik.values.remember}
                  name="remember"
                  onChange={formik.handleChange}
                  label="Remember me"
                />
              </Box>
              <Box
                className="anchor_link"
                onClick={() => navigate("/forgotpassword")}
              >
                <Typography>Forgot Password?</Typography>
              </Box>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default Login;
