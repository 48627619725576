import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../layout/MainContainer";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isString } from "../../utils/validations";
import { useEditProfileMutation } from "../../services/auth";
import CloseIcon from "@mui/icons-material/Close";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../constants";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import useAuth from "../../hooks/useAuth";
import { UploadMedia } from "../../utils/mediaUpload";
import { API_URL, END_POINTS } from "../../constants/url";

const Profile = () => {
  const user = useAuth();
  const dispatch = useAppDispatch();
  const [phoneCode, setPhoneCode] = useState("+91");
  const [image, setImage] = useState<string>("");
  const [splash, setSplash] = useState<string>("");
  console.log("splash: ", splash);

  const [updateProfile, { isLoading }] = useEditProfileMutation();

  // const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files && event.target.files[0]) {
  //     console.log(event.target.files, "event.target.files");

  //     setImage(URL.createObjectURL(event.target.files[0]));
  //   }
  // };

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      phone: user?.phone || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),
      lastName: Yup.string()
        .required("Last name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        email: formik.values.email,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        countryCode: phoneCode,
        phone: formik.values.phone,
        image: image,
        splashScreen: splash,
      };
      try {
        const response = await updateProfile(body).unwrap();
        console.log(response);
        if (response?.statusCode === 200) {
          const token = getFromStorage(STORAGE_KEYS.token);
          showToast("Profile updated successfully");
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: token || null,
            })
          );
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  const handleSlashUpload = async (e: any, type: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await fetch(API_URL + END_POINTS?.mediaUpload, {
        method: "POST",
        body: formData,
      });

      const responseData = await response.json();

      if (response.ok) {
        setSplash(responseData?.data || "");
      } else {
        // Handle error
        console.error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      // setImage(res?.data);
      if (key === "profile") {
        setImage(res?.data);
      } else {
        setSplash(res?.data);
      }
    } else {
      showError(res?.message);
    }
  };

  useEffect(() => {
    setPhoneCode(user?.countryCode || "+91");
    setImage(user?.image || "");
    setSplash(user?.splashScreen || "");
  }, [user]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Edit User Profile</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  {image ? (
                    <div className="upload_image_preview">
                      <CardMedia component="img" image={image} alt="photo" />
                      <CancelIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleImageUpload(e, "profile")
                          }
                        />
                        <Button component="span" className="upload_image_btn">
                          <img
                            src={
                              image
                                ? image
                                : "/static/images/user_placeholder.png"
                            }
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography className="custom_label">First Name</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type="text"
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="First Name"
                    id="firstName"
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography className="custom_label">Last Name</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type="text"
                    name="lastName"
                    variant="outlined"
                    fullWidth
                    placeholder="Last Name"
                    id="lastName"
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography className="custom_label">Email</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Email"
                    id="email"
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography className="custom_label">Phone Number</Typography>
                  <PhoneInput
                    enableSearch={true}
                    value={phoneCode + formik.values.phone}
                    country={"us"}
                    inputClass="phoneInput"
                    inputStyle={{ width: "100%" }}
                    buttonClass="phoneBtn"
                    placeholder="Phone Number"
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <h6 className="err_msg">
                      {formik.touched.phone && formik.errors.phone}
                    </h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography className="custom_label">
                    Upload Splash Screen
                  </Typography>
                  <div className="upload_document">
                    {splash ? (
                      <div className="inner">
                        <figure>
                          <img src={splash} alt="" />
                        </figure>
                        <span className="close" onClick={() => setSplash("")}>
                          <CloseIcon />
                        </span>{" "}
                      </div>
                    ) : (
                      <label htmlFor="icon-button-file-splash">
                        <div className="inner">
                          {" "}
                          <AddIcon />
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file-splash"
                            type="file"
                            inputProps={{
                              accept: "image/gif", // Specify the MIME type for GIF images
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleSlashUpload(e, "spash")}
                          />
                        </div>
                      </label>
                    )}
                  </div>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default Profile;
