import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomerSupport = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function createData(
    Name: string,
    Email: string,
    Queries: string,
    Description: string
  ) {
    return { Name, Email, Queries, Description };
  }
  const rows = [
    createData(
      "Abdul Hussian",
      "abdulhussian67@gmail.com",
      "Lorem Ipsum",
      " Lorem Ipsum is simply dummy text.."
    ),
  ];

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Customer Support</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Queries Received" {...a11yProps(0)} />
              <Tab label="Complaints/Disputes" {...a11yProps(1)} />
              <Tab label="Booking Related Disputes" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">{/* <SearchBar /> */}</Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Queries</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row.Name}</TableCell>
                        <TableCell>{row.Email}</TableCell>
                        <TableCell>{row.Queries}</TableCell>
                        <TableCell>{row.Description}</TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <Tooltip title="Revert">
                              <IconButton onClick={handleClickOpen}>
                                <ReplyIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">{/* <SearchBar /> */}</Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Queries</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row.Name}</TableCell>
                        <TableCell>{row.Email}</TableCell>
                        <TableCell>{row.Queries}</TableCell>
                        <TableCell>{row.Description}</TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton onClick={handleClickOpen}>
                              <ReplyIcon />
                            </IconButton>
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box className="cards_header">{/* <SearchBar /> */}</Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Queries</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row.Name}</TableCell>
                        <TableCell>{row.Email}</TableCell>
                        <TableCell>{row.Queries}</TableCell>
                        <TableCell>{row.Description}</TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton onClick={handleClickOpen}>
                              <ReplyIcon />
                            </IconButton>
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>

      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">Revert Query</Typography>
          <FormControl fullWidth>
            <TextField
              hiddenLabel
              autoFocus
              id="name"
              type="text"
              placeholder="Revert Query"
              fullWidth
            />
          </FormControl>
          <Box className="form_btn">
            <Button className="btn btn_danger" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="btn btn_primary">Send</Button>
          </Box>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default CustomerSupport;
