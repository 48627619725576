import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  useAddSubscriptionMutation,
  useLazyGetSubscriptionByIdQuery,
  useUpdateSubscriptionMutation,
} from "../../services/main";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Loader, showError, showToast } from "../../constants";
import { isNumber, isString } from "../../utils/validations";
import { UploadMedia } from "../../utils/mediaUpload";
import { Subscription } from "../../types/General";
import {
  useAddServiceMutation,
  useLazyGetServiceByIdQuery,
  useUpdateServiceMutation,
} from "../../services/service";
import EditText from "../../components/EditText";

const AddService = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState<string>("edit");
  const [features, setFeatures] = useState<string>("");
  const [loading, setLoading] = useState(true);

  const [getSubById] = useLazyGetSubscriptionByIdQuery();
  const [ServiceByIdMutation] = useLazyGetServiceByIdQuery();
  const [addService, { isLoading }] = useAddServiceMutation();
  const [updateService, updateServiceData] = useUpdateServiceMutation();

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      serviceType: "",
      serviceFee: "",
    },
    validationSchema: Yup.object({
      serviceType: Yup.string()
        .required("Service Type is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      serviceFee: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 digits are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        serviceType: formik.values.serviceType,
        serviceFees: formik.values.serviceFee,
        features: features,
      };

      if (id) {
        try {
          const response = await updateService({
            service_id: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            formik.resetForm();
            navigate("/manage-revenue", { state: "ser" });
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addService(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message);
            formik.resetForm();
            navigate("/manage-revenue", { state: "ser" });
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const getServiceDetail = async (id: string) => {
    try {
      const response = await ServiceByIdMutation({ service_id: id }).unwrap();
      if (response?.statusCode === 200) {
        formik.setFieldValue("serviceType", response?.data[0]?.serviceType);
        formik.setFieldValue("serviceFee", response?.data[0]?.serviceFees);
        setFeatures(response?.data[0]?.features || "");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getServiceDetail(id);
    } else {
      setType("add");
    }
  }, [id]);

  return (
    <>
      <Loader isLoad={isLoading || updateServiceData?.isLoading} />
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit Service" : "Add Service"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-revenue", { state: "ser" });
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Service Type
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="serviceType"
                      variant="outlined"
                      fullWidth
                      placeholder="Service Type"
                      id="serviceType"
                      onBlur={formik.handleBlur}
                      value={formik.values.serviceType}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.serviceType && formik.errors.serviceType
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Service Fees
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="serviceFee"
                      variant="outlined"
                      fullWidth
                      placeholder="Fee"
                      id="serviceFee"
                      onBlur={formik.handleBlur}
                      value={formik.values.serviceFee}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.serviceFee && formik.errors.serviceFee
                      }
                    />
                  </Grid>

                  {type === "edit" && features && id ? (
                    <Grid item xs={12}>
                      <Typography className="custom_label">Features</Typography>
                      <EditText content={features} setContent={setFeatures} />
                    </Grid>
                  ) : type === "add" ? (
                    <Grid item xs={12}>
                      <Typography className="custom_label">Features</Typography>
                      <EditText content={features} setContent={setFeatures} />
                    </Grid>
                  ) : null}
                </Grid>
              </CardContent>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddService;
