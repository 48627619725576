import { getMessaging, getToken } from "firebase/messaging";
import app from "./firebaseKeys";
import { STORAGE_KEYS, getFromStorage, setToStorage } from "../constants";

const firebaseCloudMessaging = {
  // eslint-disable-next-line consistent-return
  init: async () => {
    try {
      const messaging = getMessaging(app);
      const tokenInLocalForage = await getFromStorage(STORAGE_KEYS.fcmToken);

      if (tokenInLocalForage) {
        return tokenInLocalForage;
      }

      const status = await Notification.requestPermission();
      if (status && status === "granted") {
        const fcm_token = await getToken(messaging, {
          vapidKey:
            "BG-Teeb5_YQ2s0R1ul3_jC7jLKC2xzmytOyRfc-5AnQptRhLZK_ZWdSz-wRm_GriT_gZwPfF-qRTVN1wwjGYnr0",
        });

        if (fcm_token) {
          console.log({ fcm_token });
          await setToStorage(STORAGE_KEYS.fcmToken, fcm_token);
          return fcm_token;
        }
      }
    } catch (error) {
      console.error(error);
      return null;
    }
    // }
  },
};

export { firebaseCloudMessaging };
