/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { Loader, showError } from "../../constants";
import {
  useLazyGetAdvertisementByIdQuery,
  useLazyGetAllAdvertisementQuery,
} from "../../services/main";
import { Advertisement } from "../../types/General";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewAdvertisement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getAdvertisement] = useLazyGetAllAdvertisementQuery();

  const [advertisement, setAdvertisement] = useState<Advertisement | null>(
    null
  );

  const handleGetById = async (id: string) => {
    try {
      const response = await getAdvertisement({
        size: 10,
        page: 1,
        query: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        const filteredData = response?.data?.advertisementPost.find(
          (item: Advertisement) => item?._id === id
        );
        setAdvertisement(filteredData || null);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      handleGetById(id);
    }
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View User Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-revenue");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Post Title</Typography>
                      <Typography component="p">
                        {advertisement?.postDetails?.posttitle || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Car Plate Number</Typography>
                      <Typography component="p">
                        {advertisement?.postDetails?.carPlatenumber || "--"}
                      </Typography>{" "}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Customer Name</Typography>
                      <Typography component="p">
                        {advertisement?.userDetails?.fullName || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5"> Customer Email</Typography>
                      <Typography component="p">
                        {advertisement?.userDetails?.email || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Total Payment</Typography>
                      <Typography component="p">
                        {advertisement?.amount || "0"} SAR
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ViewAdvertisement;
