import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { UserSell } from "../../../types/General";
import moment from "moment";

type UserProps = {
  userData: UserSell[];
};

const UserPostForAuction = ({ userData }: UserProps) => {
  return (
    <>
      {/* <Box className="cards_header">
        <SearchBar />
      </Box> */}
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell>Post Title</TableCell>
              <TableCell>Car Plate Number</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Starting Bid</TableCell>
              <TableCell>Reserve Price</TableCell>
              <TableCell>Minimum Offer</TableCell>
              <TableCell>Bidding Time</TableCell>
            </TableRow>
          </TableHead>
          {userData?.length ? (
            userData?.map((row, i) => {
              return (
                <TableBody key={row?._id}>
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row?.posttitle || "--"}</TableCell>
                    <TableCell>{row?.carPlatenumber || "--"}</TableCell>
                    <TableCell>
                      {row?.categoryData?.categoryName || "--"}
                    </TableCell>
                    <TableCell>{row?.price || "0"} SAR</TableCell>
                    <TableCell>
                      {row?.startingBid ? `${row?.startingBid} SAR` : "--"}{" "}
                    </TableCell>
                    <TableCell>
                      {row?.reservePrice ? `${row?.reservePrice} SAR` : "--"}{" "}
                    </TableCell>
                    <TableCell>
                      {row?.minimumOffer ? `${row?.minimumOffer} SAR` : "--"}{" "}
                    </TableCell>
                    <TableCell>
                      {moment(row?.bidingStartTime).format("LT") || ""} -{" "}
                      {moment(row?.bidingEndTime).format("LT") || ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={6}>
                No Data Found
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
export default UserPostForAuction;
