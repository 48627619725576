import { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Button,
  FormControl,
  Tooltip,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tabs,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import {
  PaymentStatus,
  RequestStatus,
  ServiceRequest,
  ServiceStatus,
  SubAdmin,
  status,
} from "../../types/General";
import { Loader, showError } from "../../constants";
import { useLazyGetAllSubAdminQuery } from "../../services/main";
import {
  useLazyGetServiceMgmtCsvQuery,
  useLazyGetServiceMngQuery,
  useUpdateServiceMgmtMutation,
} from "../../services/service";
import moment from "moment";
import { Pagination } from "../../components";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const Services = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [getAllSubAdmin] = useLazyGetAllSubAdminQuery();
  const [getAllService, { isLoading }] = useLazyGetServiceMngQuery();
  const [getServiceCsvMethod] = useLazyGetServiceMgmtCsvQuery();
  const [updateRequest] = useUpdateServiceMgmtMutation();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [allSubAdmin, setAllSubAdmin] = useState<SubAdmin[]>([]);
  const [serviceReqest, setServiceRequest] = useState<ServiceRequest[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState(0);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getServiceManagement = async () => {
    try {
      const response = await getAllService({
        status: value,
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setServiceRequest(response?.data?.service || []);
        setTotalCount(response?.data?.count);
      } else {
        setServiceRequest([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getSubAdmin = async () => {
    try {
      const response = await getAllSubAdmin({
        page: 1,
        size: 10,
        query: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllSubAdmin(response?.data?.subAdmin || []);
      } else {
        setAllSubAdmin([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await getServiceCsvMethod({}).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };

  const handleRequestStatus = async (event: any, id: string) => {
    const body = {
      serviceId: id,
      status: value === 2 ? 5 : event.target.value,
      ...(value === 2 ? { agentId: event.target.value } : {}),
    };

    if (value === 2) {
    }

    try {
      const response = await updateRequest(body).unwrap();
      if (response?.statusCode === 200) {
        let idx = -1;
        idx = serviceReqest?.findIndex((ele) => ele?._id === id);
        if (idx > -1) {
          const updatedData = serviceReqest.map((x) => {
            if (x._id === id) {
              return {
                ...x,
                status: event.target.value,
              };
            }
            return x;
          });
          setServiceRequest(updatedData);
          getServiceManagement();
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getSubAdmin();
  }, []);

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Service Management"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getServiceManagement();
  }, [debouncedSearchTerm, page, value]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Service Management</h1>
        </div>
        <Card className="cards">
          <Box sx={{ width: "100%", marginBottom: 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="Pending" {...a11yProps(0)} />
                <Tab label="On-Going" {...a11yProps(1)} />
                <Tab label="Assign Agent" {...a11yProps(2)} />
                <Tab label="Completed" {...a11yProps(3)} />
              </Tabs>
            </Box>
          </Box>
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={handleExportCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Date/Time</TableCell>
                  <TableCell>Buyer Username</TableCell>
                  <TableCell>Service Type</TableCell>
                  <TableCell align="center">
                    {value === 2 ? "Assign Agent" : "Request Status"}
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {serviceReqest?.length ? (
                serviceReqest?.map((row, i) => {
                  return (
                    <TableBody key={row?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>
                          {moment(row?.createdAt).format(
                            "DD-MM-YYYY / h:mma"
                          ) || ""}
                        </TableCell>
                        <TableCell>
                          {row?.userDetails?.userName || "--"}
                        </TableCell>
                        <TableCell>{row?.serviceType || "--"}</TableCell>

                        <TableCell align="center">
                          {row?.status === 0 ? (
                            <FormControl fullWidth>
                              <Select
                                className="select_div"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={row?.status > 0 ? row?.status : ""}
                                onChange={(val) =>
                                  handleRequestStatus(val, row?._id)
                                }
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                              >
                                <MenuItem value="" disabled>
                                  Select
                                </MenuItem>
                                {RequestStatus?.slice(1, 3).map((item, i) => (
                                  <MenuItem key={i} value={item?.id}>
                                    {item?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : row?.status === 1 || row?.status === 3 ? (
                            <FormControl fullWidth>
                              <Select
                                className="select_div"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // onChange={(val) => handleRequestStatus(val, row?._id)}
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                                value={row?.status > 0 ? row?.status : ""}
                                onChange={(val) =>
                                  handleRequestStatus(val, row?._id)
                                }
                              >
                                <MenuItem value="" disabled>
                                  Select
                                </MenuItem>
                                {PaymentStatus?.map((item, i) => (
                                  <MenuItem key={i} value={item?.id}>
                                    {item?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : row?.status === 4 ? (
                            <FormControl fullWidth>
                              <Select
                                className="select_div"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                onChange={(val) =>
                                  handleRequestStatus(val, row?._id)
                                }
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                              >
                                <MenuItem value="" disabled>
                                  Select
                                </MenuItem>
                                {allSubAdmin?.length
                                  ? allSubAdmin.map((item, i) => (
                                      <MenuItem key={i} value={item?._id}>
                                        {item?.firstName || ""}{" "}
                                        {item?.lastName || ""}
                                      </MenuItem>
                                    ))
                                  : "No Data Found"}
                              </Select>
                            </FormControl>
                          ) : (
                            // <FormControl>
                            //   <Select
                            //     className="select_div"
                            //     labelId="demo-simple-select-label"
                            //     id="demo-simple-select"
                            //     // value={agent}
                            //     placeholder="Assign"
                            //     inputProps={{
                            //       "aria-label": "Without label",
                            //     }}
                            //     displayEmpty
                            //     onChange={(val) =>
                            //       handleRequestStatus(val, row?._id)
                            //     }
                            //   >
                            //     <MenuItem value="" disabled>
                            //       Select
                            //     </MenuItem>

                            // {allSubAdmin?.length
                            //   ? allSubAdmin.map((item, i) => (
                            //       <MenuItem key={i} value={item?._id}>
                            //         {item?.firstName || ""}{" "}
                            //         {item?.lastName || ""}
                            //       </MenuItem>
                            //     ))
                            //   : "No Data Found"}
                            //   </Select>
                            // </FormControl>
                            <p>{ServiceStatus[row?.status]}</p>
                          )}
                        </TableCell>
                        {/* <TableCell>
                        
                        </TableCell> */}
                        <TableCell align="center">
                          <Box className="table_actions">
                            <Tooltip title="View">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manageservices/view-orders/${row?._id}`
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={6}>
                    No Data Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={serviceReqest}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
    </MainContainer>
  );
};

export default Services;
