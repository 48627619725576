import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useEffect, useState } from "react";
import { showError } from "../../constants";
import { useLazyGetAuctionByIdQuery } from "../../services/main";
import { Auction } from "../../types/General";
import moment from "moment";

const ViewAuctionforSell = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [AuctionById] = useLazyGetAuctionByIdQuery();

  const [auctionData, setAuctionData] = useState<Auction>();

  const getAuctionById = async (id: string) => {
    try {
      const response = await AuctionById({ auction_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setAuctionData(response?.data?.auction[0]);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getAuctionById(id);
    }
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Auction for Sale</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-posts", { state: "auction" });
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} mb={4} className="view_box_list">
                <Typography className="detail_title" component="h2" mb={3}>
                  Post Summary
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Post Title</Typography>
                      <Typography component="p">
                        {auctionData?.posttitle || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Car Plate Number</Typography>
                      <Typography component="p">
                        {auctionData?.carPlatenumber || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Category</Typography>
                      <Typography component="p">
                        {/* {auctionData?.category || "--"} */}
                        {auctionData?.categoryData?.categoryName || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Price</Typography>
                      <Typography component="p">
                        {auctionData?.price || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Starting Bid</Typography>
                      <Typography component="p">
                        {auctionData?.startingBid || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Meaning of Plate</Typography>
                      <Typography component="p">
                        {" "}
                        {auctionData?.meaningOfPlate || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Reserve Price</Typography>
                      <Typography component="p">
                        {" "}
                        {auctionData?.reservePrice || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Minimum Offer</Typography>
                      <Typography component="p">
                        {auctionData?.minimumOffer || "--"} SAR
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Bidding Time</Typography>
                      <Typography component="p">
                        {moment(auctionData?.bidingStartTime).format("ll")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="view_box_list">
                <Typography className="detail_title" component="h2" mb={3}>
                  Customer Details
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Customer Name</Typography>
                      <Typography component="p">
                        {" "}
                        {auctionData?.userId?.fullName || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Customer Email</Typography>
                      <Typography component="p">
                        {auctionData?.userId?.email || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Customer Phone</Typography>
                      <Typography component="p">
                        {" "}
                        {auctionData?.userId?.countryCode
                          ? "+" + auctionData?.userId?.countryCode
                          : ""}{" "}
                        {auctionData?.userId?.phone || ""}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ViewAuctionforSell;
