/* eslint-disable react-hooks/exhaustive-deps */
import MainContainer from "../../layout/MainContainer";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Fab,
  FormControlLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { isString } from "../../utils/validations";
import { useEffect, useState } from "react";
import {
  useAddBannerMutation,
  useLazyGetBannerByIdQuery,
  useLazyGetCarPlateQuery,
  useUpdateBannerMutation,
} from "../../services/service";
import { AddBannerRes, CarPlateRes } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { UploadMedia } from "../../utils/mediaUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";

const AddBanner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getCarPlate] = useLazyGetCarPlateQuery();
  const [addBanner, { isLoading }] = useAddBannerMutation();
  const [getBannerById] = useLazyGetBannerByIdQuery();
  const [updateBanner, updateBannerData] = useUpdateBannerMutation();

  const [value, setValue] = useState("sell");
  const [carPlateList, setCarPlateList] = useState<CarPlateRes[]>([]);
  const [carPlateId, setCarPlateId] = useState("");
  const [error, setError] = useState(false);
  const [image, setImage] = useState<string | undefined>("");
  const [banner, setBanner] = useState<AddBannerRes | null>(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bannerTitle: banner?.title || "",
      startDate: banner?.startDate
        ? moment(banner?.startDate).format("YYYY-MM-DD")
        : "",
      endDate: banner?.endDate
        ? moment(banner?.endDate).format("YYYY-MM-DD")
        : "",
    },
    validationSchema: Yup.object({
      bannerTitle: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      startDate: Yup.string().required("This field is required"),
      endDate: Yup.string().required("This field is required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const body = {
        title: formik.values.bannerTitle,
        postId: carPlateId,
        image: image,
        startDate: formik.values.startDate,
        endDate: formik.values.endDate,
        role: value.toLocaleUpperCase(),
      };

      if (
        !image ||
        !carPlateId ||
        formik.values.startDate > formik.values.endDate
      ) {
        setError(true);
        return;
      }

      if (id && id !== "add") {
        try {
          const response = await updateBanner({
            banner_id: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            navigate("/manage-banner");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addBanner(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Banner Created Successfully");
            formik.resetForm();
            navigate("/manage-banner");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const getCarPlateHandle = async () => {
    try {
      const response = await getCarPlate({
        type: value,
        search: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setCarPlateList(response?.data || []);
      } else {
        setCarPlateList([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<object>,
    value: CarPlateRes | null
  ) => {
    if (value !== null) {
      setCarPlateId(value?._id);
    } else {
      setCarPlateId("");
    }
  };

  const getBannerDetail = async (id: string) => {
    try {
      const response = await getBannerById({ banner_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setBanner(response?.data[0]);
        setImage(response?.data[0]?.image);
        setValue(response?.data[0]?.role.toLocaleLowerCase());
        setCarPlateId(response?.data[0]?.postId);
        // setCarPlateList(response?.data[])
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getBannerDetail(id);
    }
  }, []);

  useEffect(() => {
    getCarPlateHandle();
  }, [value]);

  return (
    <div>
      <>
        <Loader isLoad={isLoading || updateBannerData?.isLoading} />
        <MainContainer>
          <div className="main_loyout">
            <div className="dashboard">
              <h1>{id ? "Edit Banner" : "Add Banner"}</h1>
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-banner");
                }}
              >
                Back
              </Button>
            </div>
            <Card className="cards">
              <form onSubmit={formik.handleSubmit}>
                <CardContent sx={{ p: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography className="custom_label">Image</Typography>
                      {image ? (
                        <div className="upload_image_preview2">
                          <CardMedia
                            component="img"
                            image={image}
                            alt="photo"
                          />
                          <CancelIcon
                            onClick={() => {
                              setImage("");
                            }}
                          />
                        </div>
                      ) : (
                        <Box className="upload_image_bnr">
                          <label htmlFor="icon-button-file">
                            <Input
                              sx={{ display: "none" }}
                              id="icon-button-file"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={handleImageUpload}
                            />
                            <Button
                              component="span"
                              className="upload_image_btn"
                            >
                              {/* <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            /> */}
                              <AddIcon />
                            </Button>
                          </label>
                        </Box>
                      )}
                      {error && !image ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="custom_label">
                        Banner Name
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type={"text"}
                        name="bannerTitle"
                        variant="outlined"
                        fullWidth
                        placeholder="Name"
                        id="bannerTitle"
                        onBlur={formik.handleBlur}
                        value={formik.values.bannerTitle}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isString(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.bannerTitle &&
                          formik.errors.bannerTitle
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="custom_label">
                        Start Date
                      </Typography>
                      <TextField
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                        className="text_field"
                        hiddenLabel
                        type={"date"}
                        name="startDate"
                        variant="outlined"
                        fullWidth
                        id="startDate"
                        value={formik.values.startDate}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.startDate && formik.errors.startDate
                        }
                      />
                      {error &&
                      formik.values.startDate > formik.values.endDate ? (
                        <h6 className="err_msg">
                          Start date should be before than end date
                        </h6>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid item xs={4}>
                      <Typography className="custom_label">End Date</Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type={"date"}
                        name="endDate"
                        variant="outlined"
                        fullWidth
                        id="endDate"
                        value={formik.values.endDate}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.endDate && formik.errors.endDate
                        }
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                      />
                    </Grid>
                    <br />
                    <Grid container spacing={2} xs={12} mt={2}>
                      <Grid item xs={6}>
                        <RadioGroup
                          className="flex"
                          aria-labelledby="demo-document-label"
                          defaultValue="sell"
                          name="document"
                        >
                          <FormControlLabel
                            className="label_checkbox"
                            value={value}
                            onChange={() => setValue("sell")}
                            control={
                              <Radio
                                checked={value === "sell" ? true : false}
                              />
                            }
                            label="Post for sale"
                          />
                          <FormControlLabel
                            className="label_checkbox"
                            value="auction"
                            onChange={() => setValue("auction")}
                            control={
                              <Radio
                                checked={value === "auction" ? true : false}
                              />
                            }
                            label="Post for auction"
                          />
                          <FormControlLabel
                            className="label_checkbox"
                            value="auction"
                            onChange={() => setValue("offer")}
                            control={
                              <Radio
                                checked={value === "offer" ? true : false}
                              />
                            }
                            label="Post for offer"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="custom_label">
                          Select Post
                        </Typography>
                        <Autocomplete
                          fullWidth
                          className="prfl_autocmplt"
                          disablePortal
                          id="combo-box-demo"
                          onChange={handleChange}
                          options={carPlateList?.length ? carPlateList : []}
                          getOptionLabel={(option) => option?.carPlatenumber}
                          sx={{ width: "auto" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Search" />
                          )}
                          value={
                            carPlateList.find(
                              (item) => item._id === carPlateId
                            ) || null
                          }
                        />
                        {error && !carPlateId ? (
                          <h6 className="err_msg">This field is required</h6>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                      onClick={() => setError(true)}
                    >
                      Save
                    </Button>
                  </div>
                </CardContent>
              </form>
            </Card>
          </div>
        </MainContainer>
      </>
    </div>
  );
};

export default AddBanner;
