/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useGetNotificationMutation,
  useLazyNotifUserIdQuery,
} from "../../services/notification";
import { Notification } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { isValidInput } from "../../utils/validations";
import { Pagination, WarnModal } from "../../components";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageNotifications = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [deleteNotifById] = useLazyNotifUserIdQuery();
  const [getNotif, { isLoading }] = useGetNotificationMutation();

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [notification, setNotification] = useState<Notification[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getNotification = async () => {
    try {
      const response = await getNotif({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setNotification(response?.data?.user || []);
        setTotalCount(response?.data?.userCount);
      } else {
        setNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteNotifById({ notif_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        try {
          const response = await getNotif({
            page: page,
            size: 10,
            query: debouncedSearchTerm.trim(),
          }).unwrap();
          if (response?.statusCode === 200) {
            setNotification(response?.data?.user || []);
            setTotalCount(response?.data?.userCount);
          } else {
            setNotification([]);
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    const permission = userData?.permission;

    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Notification"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getNotification();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {hidePermission?.isAdd || userData?.role === "ADMIN" ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-notifications/add")}
                >
                  Add Notification
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
              {notification?.length ? (
                notification?.map((row, i) => {
                  return (
                    <TableBody key={row?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>{row?.Title || "--"}</TableCell>
                        <TableCell sx={{ width: "400px" }}>
                          {row?.Message || "--"}
                        </TableCell>
                        <TableCell>
                          {moment(row?.createdAt).format("l")}
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={5}>
                    No Notification Yet.
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={notification}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="notification"
      />
    </MainContainer>
  );
};

export default ManageNotifications;
