import { END_POINTS } from "../constants/url";
import { Dashboard, Faqs, Notification } from "../types/General";
import emptySplitApi from "../utils/rtk";

type PostNotifBody = {
  Message: string;
  Title: string;
};

type PostNotifResponse = {
  statusCode: number;
  data: {
    userCount: number;
    user: Notification[];
  };
  message: string;
};

type GetTokenParams = {
  size: number;
  page: number;
  query: string;
};

type GetDashboardParams = {
  totalUsers: string;
  postType: string;
  totalOrders: string;
  auctionType: string;
  totalRevenue: string;
};

type DashboardResponse = {
  statusCode: number;
  message: string;
  data: Dashboard;
};

type FaqResponse = {
  statusCode: number;
  message: string;
  data: {
    count: number;
    data: Faqs[];
  };
};

type FaqBody = {
  question: string;
  answer: string;
};

export const notificationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postNotif: builder.mutation<PostNotifResponse, PostNotifBody>({
      query: (body) => ({
        url: END_POINTS.notification,
        method: "POST",
        body,
      }),
    }),
    getNotification: builder.mutation<PostNotifResponse, GetTokenParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.notification +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    notifUserId: builder.query<PostNotifResponse, { notif_id: string }>({
      query: ({ notif_id }) => ({
        url: `${END_POINTS.notification}/${notif_id}`,
        method: "DELETE",
      }),
    }),
    dashboard: builder.mutation<DashboardResponse, GetDashboardParams>({
      query: ({
        totalUsers,
        postType,
        totalOrders,
        auctionType,
        totalRevenue,
      }) => ({
        url:
          END_POINTS.dashboard +
          "?sellPost=" +
          postType +
          "&auctionPost=" +
          auctionType +
          "&totalOrders=" +
          totalOrders +
          "&totalUsers=" +
          totalUsers +
          "&totalRevenue=" +
          totalRevenue,
        method: "GET",
      }),
    }),
    getFaq: builder.query<FaqResponse, {}>({
      query: () => ({
        url: END_POINTS.get_faq,
        method: "GET",
      }),
    }),
    postFaq: builder.mutation<FaqResponse, FaqBody>({
      query: (body) => ({
        url: END_POINTS.get_faq,
        method: "POST",
        body,
      }),
    }),
    deleteFaq: builder.query<FaqResponse, { faq_id: string }>({
      query: ({ faq_id }) => ({
        url: `${END_POINTS.get_faq}/${faq_id}`,
        method: "DELETE",
      }),
    }),
    userNotification: builder.mutation<PostNotifResponse, GetTokenParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.userNotification +
          "?page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePostNotifMutation,
  useGetNotificationMutation,
  useLazyNotifUserIdQuery,
  useDashboardMutation,
  useLazyGetFaqQuery,
  usePostFaqMutation,
  useLazyDeleteFaqQuery,
  useUserNotificationMutation,
} = notificationApi;
