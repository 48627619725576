import {
  Badge,
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import SendIcon from "@mui/icons-material/Send";

const Chat = () => {
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Chat</h1>
        </div>
        <Card className="cards">
          <Box className="chat_box">
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <Box className="chat_left">
                  <Typography component="h2">Messages</Typography>
                  <ul className="chat_list">
                    <li className="active">
                      <figure>
                        <img
                          src="/static/images/profile_placeholder.jpg"
                          alt=""
                        />
                      </figure>
                      <div className="chat_list_info">
                        <Box className="flex">
                          <Typography component="h3">John Doe</Typography>
                          <Typography component="span">10 minutes</Typography>
                        </Box>
                        <Typography component="p">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit dolor sit amet consectetur.
                        </Typography>
                        <Badge>2</Badge>
                      </div>
                    </li>
                    <li>
                      <figure>
                        <img
                          src="/static/images/profile_placeholder.jpg"
                          alt=""
                        />
                      </figure>
                      <div className="chat_list_info">
                        <Box className="flex">
                          <Typography component="h3">John Doe</Typography>
                          <Typography component="span">10 minutes</Typography>
                        </Box>
                        <Typography component="p">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit dolor sit amet consectetur.
                        </Typography>
                        <Badge>2</Badge>
                      </div>
                    </li>
                    <li>
                      <figure>
                        <img
                          src="/static/images/profile_placeholder.jpg"
                          alt=""
                        />
                      </figure>
                      <div className="chat_list_info">
                        <Box className="flex">
                          <Typography component="h3">John Doe</Typography>
                          <Typography component="span">10 minutes</Typography>
                        </Box>
                        <Typography component="p">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit dolor sit amet consectetur.
                        </Typography>
                        <Badge>2</Badge>
                      </div>
                    </li>
                    <li>
                      <figure>
                        <img
                          src="/static/images/profile_placeholder.jpg"
                          alt=""
                        />
                      </figure>
                      <div className="chat_list_info">
                        <Box className="flex">
                          <Typography component="h3">John Doe</Typography>
                          <Typography component="span">10 minutes</Typography>
                        </Box>
                        <Typography component="p">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit dolor sit amet consectetur.
                        </Typography>
                        <Badge>2</Badge>
                      </div>
                    </li>
                    <li>
                      <figure>
                        <img
                          src="/static/images/profile_placeholder.jpg"
                          alt=""
                        />
                      </figure>
                      <div className="chat_list_info">
                        <Box className="flex">
                          <Typography component="h3">John Doe</Typography>
                          <Typography component="span">10 minutes</Typography>
                        </Box>
                        <Typography component="p">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit dolor sit amet consectetur.
                        </Typography>
                      </div>
                    </li>
                    <li>
                      <figure>
                        <img
                          src="/static/images/profile_placeholder.jpg"
                          alt=""
                        />
                      </figure>
                      <div className="chat_list_info">
                        <Box className="flex">
                          <Typography component="h3">John Doe</Typography>
                          <Typography component="span">10 minutes</Typography>
                        </Box>
                        <Typography component="p">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit dolor sit amet consectetur.
                        </Typography>
                      </div>
                    </li>
                    <li>
                      <figure>
                        <img
                          src="/static/images/profile_placeholder.jpg"
                          alt=""
                        />
                      </figure>
                      <div className="chat_list_info">
                        <Box className="flex">
                          <Typography component="h3">John Doe</Typography>
                          <Typography component="span">10 minutes</Typography>
                        </Box>
                        <Typography component="p">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit dolor sit amet consectetur.
                        </Typography>
                      </div>
                    </li>
                    <li>
                      <figure>
                        <img
                          src="/static/images/profile_placeholder.jpg"
                          alt=""
                        />
                      </figure>
                      <div className="chat_list_info">
                        <Box className="flex">
                          <Typography component="h3">John Doe</Typography>
                          <Typography component="span">10 minutes</Typography>
                        </Box>
                        <Typography component="p">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit dolor sit amet consectetur.
                        </Typography>
                      </div>
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box className="chat_right">
                  <Box className="chat_right_head">
                    <figure>
                      <img
                        src="/static/images/profile_placeholder.jpg"
                        alt=""
                      />
                    </figure>
                    <Typography component="h2">John Doe</Typography>
                  </Box>
                  <Box className="chat_body">
                    <Box className="chat_msg recieved">
                      <Box className="chat_msg_head">
                        <figure>
                          <img
                            src="/static/images/profile_placeholder.jpg"
                            alt=""
                          />
                        </figure>
                        <Typography component="h3">John Doe</Typography>
                        <Typography component="span">05:14 PM</Typography>
                      </Box>
                      <Box className="chat_msg_info">
                        <Typography component="p">
                          Hi Elena, let’s grab a coffee and go for a walk! I
                          will be free from Tuesday after 5pm.
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="chat_msg sent">
                      <Box className="chat_msg_head">
                        <figure>
                          <img
                            src="/static/images/profile_placeholder.jpg"
                            alt=""
                          />
                        </figure>
                        <Typography component="h3">John Doe</Typography>
                        <Typography component="span">05:14 PM</Typography>
                      </Box>
                      <Box className="chat_msg_info">
                        <Typography component="p">
                          Hi Elena, let’s grab a coffee and go for a walk! I
                          will be free from Tuesday after 5pm.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="chat_foot">
                    <TextField
                      hiddenLabel
                      placeholder="Type a message"
                      fullWidth
                      name="text"
                      type="message"
                      variant="outlined"
                      multiline
                    />
                    <Button>
                      <SendIcon />{" "}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default Chat;
