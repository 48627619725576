import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { UserSell } from "../../../types/General";
import moment from "moment";

type UserProps = {
  userData: UserSell[];
};

const UserEarnings = ({ userData }: UserProps) => {
  return (
    <>
      {/* <Box className="cards_header">
        <SearchBar />
      </Box> */}
      <TableContainer className="table_container">
        <Box className="heading" />
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell>Invoice no</TableCell>
              <TableCell>Buyer Details</TableCell>
              <TableCell>Date/Time</TableCell>
              <TableCell>Post Title</TableCell>
              <TableCell>Car Plate Number</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Total Payments</TableCell>
            </TableRow>
          </TableHead>
          {userData?.length ? (
            userData?.map((row, i) => (
              <TableBody>
                <TableRow>
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell># {row?.invoiceNo || 0}</TableCell>
                  <TableCell>
                    {row?.buyerDetails?.userName || ""} <br />
                    {row?.buyerDetails?.email || "--"} <br />
                    {row?.buyerDetails?.countryCode
                      ? (row?.buyerDetails?.countryCode.includes("+")
                          ? ""
                          : "+") + row?.buyerDetails?.countryCode
                      : null}{" "}
                    {row?.buyerDetails?.phone || "--"}
                  </TableCell>

                  <TableCell>
                    {moment(row?.createdAt).format("DD-MM-YYYY / h:mma") || ""}
                  </TableCell>
                  <TableCell>{row?.postDetails?.posttitle || "--"}</TableCell>
                  <TableCell>
                    {row?.postDetails?.carPlatenumber || "--"}
                  </TableCell>
                  <TableCell>
                    {row?.categoryData?.categoryName || "--"}
                  </TableCell>
                  <TableCell>{row?.price || 0} SAR</TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={8}>
                No Data Found
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
export default UserEarnings;
