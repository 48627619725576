import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from "@mui/material/FormControl";
import {
  usePostForgotPasswordMutation,
  usePostVerifyOtpMutation,
} from "../../../services/auth";
import { useAppDispatch } from "../../../hooks/store";

import { Loader, showError, showToast } from "../../../constants";
import { temporaryToken } from "../../../reducers/authSlice";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
};

const VerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const dispatch = useAppDispatch();
  const [optVerificationMutation, optVerificationMutationData] =
    usePostVerifyOtpMutation();
  const [ForgotPasswordMutation, { isLoading }] =
    usePostForgotPasswordMutation();

  const [otp, setOtp] = useState<string>("");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);

      const body = {
        key: state?.email,
        code: otp,
      };

      try {
        const response = await optVerificationMutation(body).unwrap();
        if (response?.statusCode === 200) {
          dispatch(temporaryToken({ tempToken: response?.data?.token }));
          navigate("/resetpassword", { replace: true });
        }
      } catch (error: any) {
        if (error?.data?.message) {
          showError(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  const handleResendOtp = async () => {
    let body = {
      key: state?.email,
    };
    try {
      const response = await ForgotPasswordMutation(body).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message);
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={optVerificationMutationData.isLoading || isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/forgotpassword")}
              sx={{ p: 0, position: "absolute", left: 0 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5">Verify Otp</Typography>
          </Box>
          <FormControl className="opt_fields" sx={{ width: "100%" }}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              inputStyle={otpStyle}
              inputType="tel"
            />
            <br />
            {error && otp.length !== 4 ? (
              <h6 className="err_msg">This field is required</h6>
            ) : (
              ""
            )}
          </FormControl>
          <Box sx={{ pt: 3 }}>
            <Button
              className="btn btn_primary"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Verify Otp
            </Button>
          </Box>
          {countDown === 0 ? (
            <Box
              sx={{
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography
                className="anchor_link"
                sx={{ cursor: "pointer" }}
                onClick={handleResendOtp}
              >
                Resend Otp
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                // className="anchor_link"
                sx={{
                  color: "grey",
                  pt: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                The verify code will expire in{" "}
                <p style={{ margin: 0, color: "#204e33", paddingLeft: 3 }}>
                  00 : {countDown}
                </p>
              </Typography>
            </Box>
          )}
        </div>
      </Container>
    </Box>
  );
};

export default VerifyOtp;
