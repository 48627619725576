import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { UserSell } from "../../../types/General";
import moment from "moment";

type UserProps = {
  userData: UserSell[];
};

const UserBidding = ({ userData }: UserProps) => {
  return (
    <>
      {/* <Box className="cards_header">
          <SearchBar />
        </Box> */}
      <TableContainer className="table_container">
        <Box className="heading" />
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell>Post Title</TableCell>
              <TableCell>Car Plate Number</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Bid Price</TableCell>
              <TableCell align="center">Bidding Winner</TableCell>
            </TableRow>
          </TableHead>
          {userData?.length ? (
            userData?.map((row, i) => (
              <TableBody>
                <TableRow>
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell>{row?.posttitle || "--"}</TableCell>
                  <TableCell>{row?.carPlatenumber || "--"}</TableCell>
                  <TableCell>
                    {row?.categoryData?.categoryName || "--"}
                  </TableCell>
                  <TableCell> {row?.price || 0} SAR</TableCell>
                  <TableCell align="center">
                    {row?.isWinner ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={8}>
                No Data Found
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
export default UserBidding;
