import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLazyGetUserQuery } from "../../services/auth";
import { useAddAuctionTeaserMutation } from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { isNumber } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import moment from "moment";

const Setting = () => {
  const user = useAuth();

  const [addAuctiondateAndTeaser, { isLoading }] =
    useAddAuctionTeaserMutation();

  const [teaser, setTeaser] = useState<string>("");
  const [error, setError] = useState(false);
  const [getUser] = useLazyGetUserQuery();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sellingCommission: user?.sellingCommission || "",
      auctionStartDate: user?.auctionStartDate
        ? moment(user?.auctionStartDate).format("YYYY-MM-DD")
        : "",
      auctionEndDate: user?.auctionEndDate
        ? moment(user?.auctionEndDate).format("YYYY-MM-DD")
        : "",
    },
    validationSchema: Yup.object({
      sellingCommission: Yup.string().required("This field is required"),
      auctionStartDate: Yup.string().required("This field is required"),
      auctionEndDate: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (Number(formik.values.sellingCommission) > 100) {
        console.log(
          "formik.values.sellingCommission: ",
          formik.values.sellingCommission
        );
        return true;
      }

      const body = {
        sellingCommission: formik.values.sellingCommission,
        auctionStartDate: formik.values.auctionStartDate,
        auctionEndDate: formik.values.auctionEndDate,
        auctionTeaser: teaser,
      };

      try {
        const response = await addAuctiondateAndTeaser(body).unwrap();
        console.log(response);
        if (response?.statusCode === 200) {
          showToast("Updated Successfully");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (user) {
      setTeaser(user.auctionTeaser);
    }
  }, [user]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Settings</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className="custom_label">
                  Selling Price Commission (%)
                </Typography>
                <TextField
                  placeholder="Selling Price Commission (%)"
                  className="text_field"
                  hiddenLabel
                  type="text"
                  variant="outlined"
                  fullWidth
                  name="sellingCommission"
                  id="sellingCommission"
                  inputProps={{ maxLength: 3 }}
                  value={formik.values.sellingCommission}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isNumber(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  helperText={
                    formik.touched.sellingCommission &&
                    formik.errors.sellingCommission
                  }
                />
                {error && Number(formik.values.sellingCommission) > 100 ? (
                  <h6 className="err_msg">
                    Please enter a number between 0 and 100.
                  </h6>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Typography className="custom_label">
                  Auction Start Date
                </Typography>
                <TextField
                  inputProps={{
                    min: new Date().toISOString().split("T")[0],
                  }}
                  className="text_field"
                  hiddenLabel
                  type="date"
                  name="auctionStartDate"
                  variant="outlined"
                  fullWidth
                  id="auctionStartDate"
                  value={formik.values.auctionStartDate}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.auctionStartDate &&
                    formik.errors.auctionStartDate
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className="custom_label">
                  Auction End Date
                </Typography>
                <TextField
                  inputProps={{
                    min: new Date().toISOString().split("T")[0],
                  }}
                  className="text_field"
                  hiddenLabel
                  type="date"
                  variant="outlined"
                  fullWidth
                  name="auctionEndDate"
                  id="auctionEndDate"
                  value={formik.values.auctionEndDate}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.auctionEndDate &&
                    formik.errors.auctionEndDate
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className="custom_label">Auction Teaser</Typography>

                {teaser ? (
                  <EditText content={teaser} setContent={setTeaser} />
                ) : null}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
              <Grid item xs={6} />
            </Grid>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default Setting;
