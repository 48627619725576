import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import "../Users.scss";
import UserPostForSell from "./PostForSell";
import UserPostForAuction from "./PostForAuction";
import { useLazyGetUserIdQuery } from "../../../services/main";
import { Loader, showError } from "../../../constants";
import { AllUser, UserSell } from "../../../types/General";
import SearchBar from "../../../components/SearchBar";
import { isValidInput } from "../../../utils/validations";
import UserEarnings from "./Earnings";
import { Pagination } from "../../../components";
import UserBidding from "./Bidding";
import SellerAgent from "./SellerAgent";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getUserById, { isLoading }] = useLazyGetUserIdQuery();

  const [value, setValue] = useState(0);
  const [userData, setUserData] = useState<AllUser | null>(null);
  const [userSell, setUserSell] = useState<UserSell[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getUserDetail = async (id: string) => {
    try {
      const response = await getUserById({
        user_id: id,
        status: value,
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserData(response?.data?.user);
        setUserSell(response?.data?.post || []);
        setTotalCount(response?.data?.count || 0);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getUserDetail(id);
    }
  }, [value, debouncedSearchTerm, page]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View User Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manageusers");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={2}>
                <figure className="profile_img">
                  <img
                    src={
                      userData?.image
                        ? userData?.image
                        : "/static/images/user_placeholder.png"
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Full Name</Typography>
                      <Typography component="p">
                        {userData?.userName || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">User Name</Typography>
                      <Typography component="p">
                        {userData?.userName || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">
                        {userData?.countryCode
                          ? (userData?.countryCode.includes("+") ? "" : "+") +
                            userData?.countryCode
                          : null}{" "}
                        {userData?.phone || ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        {userData?.email || "--"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Earnings</Typography>
                      <Typography component="p">
                        {userData?.totalearnings || 0} SAR
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Account Status</Typography>
                      <Typography component="p">
                        {userData?.isBlocked ? "Inactive" : "Active"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography className="detail_title" component="h2" mb={3}>
                      Plan Details
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <Box>
                          <Typography component="h5">Plan Name</Typography>
                          <Typography component="p">
                            {userData?.planName || "--"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <Typography component="h5">Plan Type</Typography>
                          <Typography component="p">
                            {userData?.typeOfPlan || "--"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <Typography component="h5">Plan Price</Typography>
                          <Typography component="p">
                            {userData?.planPrice || "--"}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Post For Sale" {...a11yProps(0)} />
              <Tab label="Post For Auction" {...a11yProps(1)} />
              <Tab label="Earnings" {...a11yProps(2)} />
              <Tab label="Bidding" {...a11yProps(3)} />
              <Tab label="Seller Agent" {...a11yProps(4)} />
            </Tabs>
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <CustomTabPanel value={value} index={0}>
              <UserPostForSell userSellData={userSell} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <UserPostForAuction userData={userSell} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <UserEarnings userData={userSell} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <UserBidding userData={userSell} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <SellerAgent userData={userSell} />
            </CustomTabPanel>
          </Box>
        </Card>
        <Pagination
          setPage={setPage}
          module={userSell}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
    </MainContainer>
  );
};

export default UserDetails;
