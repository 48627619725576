import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../../components/SearchBar";
import { UserSell } from "../../../types/General";

type UserProps = {
  userSellData: UserSell[];
};

const UserPostForSell = ({ userSellData }: UserProps) => {
  return (
    <>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell>Post Title</TableCell>
              <TableCell>Car Plate Number</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Price Type</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          {userSellData?.length ? (
            userSellData?.map((row, i) => {
              return (
                <TableBody key={row?._id}>
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row?.posttitle || "--"}</TableCell>
                    <TableCell>{row?.carPlatenumber || "--"} </TableCell>
                    <TableCell>
                      {row?.categoryData?.categoryName || "--"}
                    </TableCell>
                    <TableCell>{row?.typeOfPrice || "--"}</TableCell>
                    <TableCell>{row?.price || 0} SAR</TableCell>
                  </TableRow>
                </TableBody>
              );
            })
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={9}>
                No Data Found
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
export default UserPostForSell;
