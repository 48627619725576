/* eslint-disable react-hooks/exhaustive-deps */
import { PropsWithoutRef, useEffect, useState } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InventoryIcon from "@mui/icons-material/Inventory";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import { SIDEBAR_WIDTH } from "../constants";
import useAuth from "../hooks/useAuth";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const userData = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Manage Users",
      icon: <PeopleIcon />,
      key: "2",
      selected: "/manageusers",
    },
    {
      label: "Manage Car Plate Posts",
      icon: <WysiwygIcon />,
      key: "3",
      selected: "/manage-posts",
    },
    {
      label: "Manage Categories",
      icon: <ListAltIcon />,
      key: "4",
      selected: "/manage-categories",
    },
    // {
    //   label: "Service Management",
    //   icon: <InventoryIcon />,
    //   key: "5",
    //   selected: "/manageservices",
    // },
    {
      label: "Manage Revenue",
      icon: <AttachMoneyIcon />,
      key: "6",
      selected: "/manage-revenue",
    },
    {
      label: "Reports and Analytics",
      icon: <BarChartIcon />,
      key: "7",
      selected: "/analytics",
    },
    {
      label: "Manage CMS",
      icon: <SummarizeTwoToneIcon />,
      key: "8",
      selected: "/cms",
    },
    {
      label: "Manage Notification",
      icon: <NotificationsActiveIcon />,
      key: "9",
      selected: "/manage-notifications",
    },
    // {
    //   label: "Manage Customer Support",
    //   icon: <LiveHelpIcon />,
    //   key: "10",
    //   selected: "/customer-support",
    // },
    {
      label: "Manage Banner",
      icon: <ViewCarouselIcon />,
      key: "12",
      selected: "/manage-banner",
    },
    {
      label: "Manage Sub-Admin",
      icon: <PersonAddIcon />,
      key: "11",
      selected: "/manage-subAdmin",
    },
    {
      label: "Settings",
      icon: <SettingsIcon />,
      key: "11",
      selected: "/setting",
    },
  ];
  const [list, setList] = useState<any>(
    userData?.role === "ADMIN" ? items : userData?.permission
  );

  useEffect(() => {
    let arr = [];
    if (userData?.role === "ADMIN") {
      setList(items);
    } else if (userData?.permission?.length) {
      const permission = userData?.permission;
      arr = items.map((item) => {
        let idx = -1;
        idx = permission?.findIndex((ele: any) => ele?.label === item?.label);
        if (idx > -1) {
          if (permission[idx].isView) {
            return item;
          }
          return false;
        }
      });
      setList(arr);
    }
  }, [userData]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure style={{ width: "180px", height: "60px", margin: "auto" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src="/static/images/logo.png"
              alt=""
              onClick={() => navigate("/dashboard")}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List key={item?.label} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.label}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={() => navigate("/dashboard")}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List key={item?.label} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      // className="lst-itm"
                      // selected={
                      //   location.pathname.includes(item.selected) === item.selected
                      // }
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.label}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
        {/* {drawer} */}
      </Drawer>
    </Box>
  );
}
