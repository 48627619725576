import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  InputAdornment,
  IconButton,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isString } from "../../utils/validations";
import PhoneInput from "react-phone-input-2";
import { UploadMedia } from "../../utils/mediaUpload";
import { Loader, showError, showToast } from "../../constants";
import {
  useAddSubAdminMutation,
  useLazyGetSubAdminByIdQuery,
  useUpdateSubAdminByIdMutation,
} from "../../services/main";
import { SubAdmin } from "../../types/General";

interface DataType {
  _id?: string;
  label: string;
  isAdd: boolean;
  isView: boolean;
  isDelete?: boolean;
  disabled?: boolean;
}

const AddSubAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [addSubAdmin, { isLoading }] = useAddSubAdminMutation();
  const [subAdminById] = useLazyGetSubAdminByIdQuery();
  const [updateSubAdmin] = useUpdateSubAdminByIdMutation();

  const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [newData, setNewData] = useState<DataType[]>([] as any);
  const [subAdminData, setSubAdminData] = useState<SubAdmin | null>(null);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
    setCountryName(country?.countryCode);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const [permissions, setPermissions] = useState<DataType[]>([
    {
      _id: "1",
      label: "Dashboard",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },

    {
      _id: "3",
      label: "Manage Users",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "4",
      label: "Manage Car Plate Posts",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "5",
      label: "Manage Categories",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "6",
      label: "Service Management",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "7",
      label: "Manage Revenue",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "8",
      label: "Reports and Analytics",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "9",
      label: "Manage Notification",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "10",
      label: "Manage CMS",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "11",
      label: "Manage Banner",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    // {
    //   _id: "12",
    //   label: "Manage Customer Support",
    //   isAdd: false,
    //   isView: false,
    //   isDelete: false,
    //   disabled: true,
    // },
  ]);

  const onSelectChange = (key: any, type: string) => {
    let idx = permissions.findIndex((obj) => obj.label === key.label);
    if (type === "addEdit") {
      permissions[idx].isAdd = !key.isAdd;
      if (key.isAdd) {
        permissions[idx].isView = true;
      } else {
        permissions[idx].isView = false;
      }
    } else if (type === "isview") {
      permissions[idx].isView = !key.isView;
    } else {
      permissions[idx].isDelete = !key.isDelete;
      if (key.isDelete) {
        permissions[idx].isView = true;
      } else {
        permissions[idx].isView = false;
      }
    }
    setPermissions([...permissions]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: subAdminData?.firstName || "",
      lastName: subAdminData?.lastName || "",
      email: subAdminData?.email || "",
      phone: subAdminData?.phone || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      firstName: Yup.string()
        .required("First name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      lastName: Yup.string()
        .required("Last name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      permissions?.length > 0 &&
        permissions.map((element) => {
          const obj = {
            label: element?.label,
            isAdd: element.isAdd,
            isView: element.isView,
            isDelete: element.isDelete,
          };
          if (obj) {
            newData?.push(obj);
            setNewData([...newData]);
          }
        });

      let body = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: formik.values.phone,
        ...(image ? { image: image } : {}),
        role: "SUBADMIN",
        permission: newData.filter((element) => {
          return (
            element.isView === true ||
            element.isAdd === true ||
            element.isDelete === true
          );
        }),
      };

      if (id && id !== "add") {
        try {
          const response = await updateSubAdmin({
            subAmin_id: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            navigate("/manage-subAdmin");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addSubAdmin(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Subadmin Created Sucessfully");
            navigate("/manage-subAdmin");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const getSubAdminById = async (id: string) => {
    try {
      const response = await subAdminById({ subAmin_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setSubAdminData(response?.data);
        setPhoneCode(response?.data?.countryCode || "+91");
        setImage(response?.data?.image || "");
        const modifiedArr2 = permissions.map((item) => {
          const foundItem = response?.data?.permission?.find(
            (i) => i?.label === item.label
          );
          if (foundItem) {
            return {
              ...item,
              isView: foundItem.isView,
              isAdd: foundItem.isAdd,
              isDelete: foundItem.isDelete,
            };
          }
          return item;
        });
        setPermissions(modifiedArr2);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getSubAdminById(id);
    }
  }, []);

  return (
    <>
      <MainContainer>
        <Loader isLoad={isLoading} />
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit Sub-Admin" : "Add Sub-Admin"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subAdmin");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                            setFileName("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">First Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      placeholder="Name"
                      id="firstName"
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Last Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      placeholder="Name"
                      id="lastName"
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      id="email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      value={phoneCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      buttonClass="phoneBtn"
                      placeholder="Phone Number"
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {/* <Grid item xs={4}>
                    <Typography className="custom_label">Password</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      name="password"
                      variant="outlined"
                      fullWidth
                      placeholder="Password"
                      id="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="eye_btn" position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <TableContainer className="table_container">
                      <Box className="heading"></Box>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Module</TableCell>
                            <TableCell>Add/Edit</TableCell>
                            <TableCell>View</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {permissions?.map((row, i) => (
                            <TableRow key={row?._id}>
                              <TableCell>{row?.label}</TableCell>
                              <TableCell>
                                <Checkbox
                                  {...label}
                                  onChange={() =>
                                    onSelectChange(row, "addEdit")
                                  }
                                  checked={row?.isAdd || false}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  {...label}
                                  onChange={() => onSelectChange(row, "isview")}
                                  checked={row?.isView || false}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  {...label}
                                  onChange={() => onSelectChange(row, "isdel")}
                                  checked={row?.isDelete || false}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddSubAdmin;
