import { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyViewAllOfferByIdQuery } from "../../services/main";
import { PostSell } from "../../types/General";
import { showError } from "../../constants";
import moment from "moment";
import { Pagination } from "../../components";

const ViewOffer = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [viewOffer, setViewOffer] = useState<PostSell[]>([]);

  const [viewAllOfferMutation] = useLazyViewAllOfferByIdQuery();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  let totalPages = Math.ceil(totalCount / 10);

  const handleViewAllOffer = async (id: string) => {
    try {
      const response = await viewAllOfferMutation({
        Offer_Id: id,
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setViewOffer(response?.data?.post || []);
        setTotalCount(response?.data?.sellCount);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    handleViewAllOffer(id as string);
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Offer</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-posts")}
              >
                Back
              </Button>
            </Box>
          </Box>

          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              {viewOffer?.length ? (
                viewOffer?.map((row, i) => (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        {" "}
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>{row?.userName || "--"}</TableCell>
                      <TableCell>{row?.offerPrice || 0} SAR </TableCell>
                      <TableCell>
                        {moment(row?.createdAt).format("MMM Do YY") || ""}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={5}>
                    No Data Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Pagination
            setPage={setPage}
            module={viewOffer}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        </Card>
      </div>
    </MainContainer>
  );
};

export default ViewOffer;
