import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyGetBiderByAuctionIdQuery } from "../../services/main";
import { Loader, showError } from "../../constants";
import { AuctionBidder } from "../../types/General";
import moment from "moment";

const AuctionBids = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [bidderByAuction, { isLoading }] = useLazyGetBiderByAuctionIdQuery();

  const [bidderData, setBidderData] = useState<AuctionBidder[]>([]);

  const getAuctionById = async (id: string) => {
    try {
      const response = await bidderByAuction({ auction_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setBidderData(response?.data?.bidders);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (id && id !== "add") {
      getAuctionById(id);
    }
  }, []);
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View User Biddings</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-posts", { state: "auction" });
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  {state === "yes" ? (
                    <TableCell align="center">Winner</TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              {bidderData?.length ? (
                bidderData?.map((row, i) => {
                  return (
                    <TableBody key={i}>
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row?.fullName || "--"}</TableCell>
                        <TableCell>{row?.email || "--"}</TableCell>
                        <TableCell align="center">
                          {row?.countryCode
                            ? (row?.countryCode.includes("+") ? "" : "+") +
                              row?.countryCode
                            : null}{" "}
                          {row?.phone || ""}
                        </TableCell>
                        <TableCell>
                          {moment(row?.createdAt).format("MMM Do YY")}
                        </TableCell>
                        <TableCell>{row?.price || 0} SAR</TableCell>

                        {state === "yes" ? (
                          <TableCell align="center">
                            {row?.isWinner ? "Winner" : "Looser"}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={5}>
                    No Data Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AuctionBids;
