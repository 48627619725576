import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Dispatch, SetStateAction, useEffect } from "react";

type SearchProps = {
  value?: string;
  onChange?: any;
  onCross?: () => void;
  searchTerm: string;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
};

const SearchBar = ({
  value,
  onChange,
  onCross,
  setDebouncedSearchTerm,
  searchTerm,
}: SearchProps) => {
  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  return (
    <Box className="search_bar">
      <TextField
        value={value}
        onChange={onChange}
        hiddenLabel
        placeholder="Search here"
        className="txt_inpt"
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="search_icon">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" className="cross_btn">
              {value ? <ClearIcon onClick={onCross} /> : null}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
