import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { UserSell } from "../../../types/General";

type UserProps = {
  userData: UserSell[];
};

const SellerAgent = ({ userData }: UserProps) => {
  return (
    <>
      <TableContainer className="table_container">
        <Box className="heading" />
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell>Agent Name</TableCell>
              <TableCell>Agent Phone No</TableCell>
            </TableRow>
          </TableHead>
          {userData?.length ? (
            userData?.map((row, i) => (
              <TableBody>
                <TableRow>
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell>{row?.agentName || "--"}</TableCell>
                  <TableCell>
                    {" "}
                    {row?.countryCode
                      ? (row?.countryCode.includes("+") ? "" : "+") +
                        row?.countryCode
                      : null}{" "}
                    {row?.phone || "--"}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={3}>
                No Data Found
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
export default SellerAgent;
